import React from 'react';
import { Segment, Loader as SemanticLoader } from 'semantic-ui-react';
import './styles.scss'

const Loader = () => (
  <Segment className='segment-loader'>
    <SemanticLoader active inline='centered' size='huge'>
      <h3>Cargando...</h3>
    </SemanticLoader>
  </Segment>
);

export default Loader;
