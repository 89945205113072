import { createAction } from 'redux-actions';

const TYPES = {
  INIT: 'INIT',
  INIT_SUCCESS: 'INIT_SUCCESS',
  INIT_ERROR: 'INIT_ERROR',

  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',

  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  
  GET_PROFESSIONALS: 'GET_PROFESSIONALS',
  GET_PROFESSIONALS_SUCCESS: 'GET_PROFESSIONALS_SUCCESS',
  GET_PROFESSIONALS_ERROR: 'GET_PROFESSIONALS_ERROR',

  GET_CLIENTS: 'GET_CLIENTS',
  GET_CLIENTS_SUCCESS: 'GET_CLIENTS_SUCCESS',
  GET_CLIENTS_ERROR: 'GET_CLIENTS_ERROR',

  GET_CLIENT_DEBTS: 'GET_CLIENT_DEBTS',
  GET_CLIENT_DEBTS_SUCCESS: 'GET_CLIENT_DEBTS_SUCCESS',
  GET_CLIENT_DEBTS_ERROR: 'GET_CLIENT_DEBTS_ERROR',

  GET_USER: 'GET_USER',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_ERROR: 'GET_USER_ERROR',

  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',

  SEND_NOTIFICATION: 'SEND_NOTIFICATION',
  SEND_NOTIFICATION_SUCCESS: 'SEND_NOTIFICATION_SUCCESS',
  SEND_NOTIFICATION_ERROR: 'SEND_NOTIFICATION_ERROR',

  UPDATE_CLIENT_DEBTS: 'UPDATE_CLIENT_DEBTS',
  UPDATE_CLIENT_DEBTS_SUCCESS: 'UPDATE_CLIENT_DEBTS_SUCCESS',
  UPDATE_CLIENT_DEBTS_ERROR: 'UPDATE_CLIENT_DEBTS_ERROR',
};

const ACTIONS = {
  INIT: createAction(TYPES.INIT),
  INIT_SUCCESS: createAction(TYPES.INIT_SUCCESS),
  INIT_ERROR: createAction(TYPES.INIT_ERROR),
  
  LOGOUT: createAction(TYPES.LOGOUT),
  LOGOUT_SUCCESS: createAction(TYPES.LOGOUT_SUCCESS),
  LOGOUT_ERROR: createAction(TYPES.LOGOUT_ERROR),

  LOGIN: createAction(TYPES.LOGIN),
  LOGIN_SUCCESS: createAction(TYPES.LOGIN_SUCCESS),
  LOGIN_ERROR: createAction(TYPES.LOGIN_ERROR),

  GET_PROFESSIONALS: createAction(TYPES.GET_PROFESSIONALS),
  GET_PROFESSIONALS_SUCCESS: createAction(TYPES.GET_PROFESSIONALS_SUCCESS),
  GET_PROFESSIONALS_ERROR: createAction(TYPES.GET_PROFESSIONALS_ERROR),

  GET_CLIENTS: createAction(TYPES.GET_CLIENTS),
  GET_CLIENTS_SUCCESS: createAction(TYPES.GET_CLIENTS_SUCCESS),
  GET_CLIENTS_ERROR: createAction(TYPES.GET_CLIENTS_ERROR),

  GET_CLIENT_DEBTS: createAction(TYPES.GET_CLIENT_DEBTS),
  GET_CLIENT_DEBTS_SUCCESS: createAction(TYPES.GET_CLIENT_DEBTS_SUCCESS),
  GET_CLIENT_DEBTS_ERROR: createAction(TYPES.GET_CLIENT_DEBTS_ERROR),

  GET_USER: createAction(TYPES.GET_USER),
  GET_USER_SUCCESS: createAction(TYPES.GET_USER_SUCCESS),
  GET_USER_ERROR: createAction(TYPES.GET_USER_ERROR),

  UPDATE_USER: createAction(TYPES.UPDATE_USER),
  UPDATE_USER_SUCCESS: createAction(TYPES.UPDATE_USER_SUCCESS),
  UPDATE_USER_ERROR: createAction(TYPES.UPDATE_USER_ERROR),

  SEND_NOTIFICATION: createAction(TYPES.SEND_NOTIFICATION),
  SEND_NOTIFICATION_SUCCESS: createAction(TYPES.SEND_NOTIFICATION_SUCCESS),
  SEND_NOTIFICATION_ERROR: createAction(TYPES.SEND_NOTIFICATION_ERROR),

  UPDATE_CLIENT_DEBTS: createAction(TYPES.UPDATE_CLIENT_DEBTS),
  UPDATE_CLIENT_DEBTS_SUCCESS: createAction(TYPES.UPDATE_CLIENT_DEBTS_SUCCESS),
  UPDATE_CLIENT_DEBTS_ERROR: createAction(TYPES.UPDATE_CLIENT_DEBTS_ERROR),
};

export default ACTIONS;
