import React, { useState, useEffect } from 'react';
import { Icon, Menu, Table, Button, Header, Select, Grid, Input } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { filter } from 'lodash';

import ExcelHolper from '../ExcelHolper';

const ActiveTable = ({ items, cities }) => {
  const [paginationCount, setPaginationCount] = useState(1);
  const [paginationIndex, setPaginationIndex] = useState(1);
  const [holpers, setHolpers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [city, setCity] = useState('');

  useEffect(() => {
    setPaginationCount(Math.ceil(items.length / 10));

    setHolpers(items);
  }, [items]);

  useEffect(() => {
    if (!searchText && !city) {
      setHolpers(items);
    } else {
      setHolpers(
        filter(items, (i) => {
          return ((i.name ? i.name.toLowerCase().includes(searchText) : false) || (i.ci ? i.ci.includes(searchText) : false))
            && (city ? i.city === city : true)
        }));
    }
  }, [searchText, city, items])

  const renderPaginationIndexes = () => {
    const indexes = [];

    for (let i = 0; i < paginationCount; i++) {
      indexes.push(<Menu.Item disabled={paginationIndex === i + 1} key={`active-${i}`} as='a' onClick={() => setPaginationIndex(i + 1)}>{i + 1}</Menu.Item>);
    }

    return indexes;
  };

  return (
    <>
      {/* <Header as='h5' color='grey' textAlign='center'>En este apartado puedes revisar la base de datos de holpers y su estado.</Header> */}

      <Grid>
        <Grid.Row columns={3}>
          <Grid.Column floated='left'>
            <ExcelHolper items={holpers} />
          </Grid.Column>
          <Grid.Column floated='right'>
            <Select
              placeholder='Ciudad'
              options={cities.map(c => ({ key: c.id, value: c.name === 'Todos' ? null : c.name, text: c.name }))}
              defaultValue={city}
              onChange={(e, { value }) => { setCity(value); }}
            />
          </Grid.Column>

          <Grid.Column floated='right' >
            <Input value={searchText} onChange={(e) => { setSearchText(e.target.value); }} icon='search' placeholder='Busca por el nombre o cédula' iconPosition='left' transparent fluid />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>NOMBRE</Table.HeaderCell>
                  <Table.HeaderCell>CÉDULA</Table.HeaderCell>
                  <Table.HeaderCell>CELULAR</Table.HeaderCell>
                  {/* <Table.HeaderCell>CORREO</Table.HeaderCell> */}
                  <Table.HeaderCell>CIUDAD</Table.HeaderCell>
                  <Table.HeaderCell>MUNICIPIO</Table.HeaderCell>
                  <Table.HeaderCell>CONTRATACIONES</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {holpers.map((professional) => (
                  <Table.Row key={professional.id}>
                    <Table.Cell>{professional.name}</Table.Cell>
                    <Table.Cell>{professional.ci}</Table.Cell>
                    <Table.Cell>{professional.phone}</Table.Cell>
                    {/* <Table.Cell>{professional.email}</Table.Cell> */}
                    <Table.Cell>{professional.city}</Table.Cell>
                    <Table.Cell>{professional.address ? professional.address.find(a => a.default)?.municipality : 'N/A'}</Table.Cell>
                    <Table.Cell>{professional.hiringCount}</Table.Cell>
                    <Table.Cell>
                      <Button animated='fade' basic circular as={NavLink} to={`/holpers/${professional.id}/active`}>
                        <Button.Content visible>Ver más</Button.Content>
                        <Button.Content hidden>
                          <Icon name='eye' />
                        </Button.Content>
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              {/* <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='12'>
                    <Menu floated='right' pagination>
                      <Menu.Item as='a' icon disabled={paginationIndex === 1}>
                        <Icon name='chevron left' />
                      </Menu.Item>
                      {renderPaginationIndexes()}
                      <Menu.Item as='a' icon disabled={paginationIndex === paginationCount || !paginationCount}>
                        <Icon name='chevron right' />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer> */}
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default ActiveTable;