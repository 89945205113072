import { createAction } from 'redux-actions';

const TYPES = {
  GET_EDUCATION: 'GET_EDUCATION',
  GET_EDUCATION_SUCCESS: 'GET_EDUCATION_SUCCESS',
  GET_EDUCATION_ERROR: 'GET_EDUCATION_ERROR',

  GET_CONFIGS: 'GET_CONFIGS',
  GET_CONFIGS_SUCCESS: 'GET_CONFIGS_SUCCESS',
  GET_CONFIGS_ERROR: 'GET_CONFIGS_ERROR',

  SEND_PUSH_NOTIFICATION: 'SEND_PUSH_NOTIFICATION',
  SEND_PUSH_NOTIFICATION_SUCCESS: 'SEND_PUSH_NOTIFICATION_SUCCESS',
  SEND_PUSH_NOTIFICATION_ERROR: 'SEND_PUSH_NOTIFICATION_ERROR',

  SEND_EMAIL_BY_TEMPLATE: 'SEND_EMAIL_BY_TEMPLATE',
  SEND_EMAIL_BY_TEMPLATE_SUCCESS: 'SEND_EMAIL_BY_TEMPLATE_SUCCESS',
  SEND_EMAIL_BY_TEMPLATE_ERROR: 'SEND_EMAIL_BY_TEMPLATE_ERROR',

  GET_DASHBOARD: 'GET_DASHBOARD',
  GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
  GET_DASHBOARD_ERROR: 'GET_DASHBOARD_ERROR',

  GET_CITIES: 'GET_CITIES',
  GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
  GET_CITIES_ERROR: 'GET_CITIES_ERROR',

  GET_PROFESSIONS: 'GET_PROFESSIONS',
  GET_PROFESSIONS_SUCCESS: 'GET_PROFESSIONS_SUCCESS',
  GET_PROFESSIONS_ERROR: 'GET_PROFESSIONS_ERROR',

  UPLOAD_FILE: 'UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR: 'UPLOAD_FILE_ERROR',
};

  const ACTIONS = {
    GET_EDUCATION: createAction(TYPES.GET_EDUCATION),
    GET_EDUCATION_SUCCESS: createAction(TYPES.GET_EDUCATION_SUCCESS),
    GET_EDUCATION_ERROR: createAction(TYPES.GET_EDUCATION_ERROR),

    GET_CONFIGS: createAction(TYPES.GET_CONFIGS),
    GET_CONFIGS_SUCCESS: createAction(TYPES.GET_CONFIGS_SUCCESS),
    GET_CONFIGS_ERROR: createAction(TYPES.GET_CONFIGS_ERROR),

    SEND_PUSH_NOTIFICATION: createAction(TYPES.SEND_PUSH_NOTIFICATION),
    SEND_PUSH_NOTIFICATION_SUCCESS: createAction(TYPES.SEND_PUSH_NOTIFICATION_SUCCESS),
    SEND_PUSH_NOTIFICATION_ERROR: createAction(TYPES.SEND_PUSH_NOTIFICATION_ERROR),

    SEND_EMAIL_BY_TEMPLATE: createAction(TYPES.SEND_EMAIL_BY_TEMPLATE),
    SEND_EMAIL_BY_TEMPLATE_SUCCESS: createAction(TYPES.SEND_EMAIL_BY_TEMPLATE_SUCCESS),
    SEND_EMAIL_BY_TEMPLATE_ERROR: createAction(TYPES.SEND_EMAIL_BY_TEMPLATE_ERROR),

    GET_DASHBOARD: createAction(TYPES.GET_DASHBOARD),
    GET_DASHBOARD_SUCCESS: createAction(TYPES.GET_DASHBOARD_SUCCESS),
    GET_DASHBOARD_ERROR: createAction(TYPES.GET_DASHBOARD_ERROR),

    GET_CITIES: createAction(TYPES.GET_CITIES),
    GET_CITIES_SUCCESS: createAction(TYPES.GET_CITIES_SUCCESS),
    GET_CITIES_ERROR: createAction(TYPES.GET_CITIES_ERROR),

    GET_PROFESSIONS: createAction(TYPES.GET_PROFESSIONS),
    GET_PROFESSIONS_SUCCESS: createAction(TYPES.GET_PROFESSIONS_SUCCESS),
    GET_PROFESSIONS_ERROR: createAction(TYPES.GET_PROFESSIONS_ERROR),

    UPLOAD_FILE: createAction(TYPES.UPLOAD_FILE),
    UPLOAD_FILE_SUCCESS: createAction(TYPES.UPLOAD_FILE_SUCCESS),
    UPLOAD_FILE_ERROR: createAction(TYPES.UPLOAD_FILE_ERROR)
};

export default ACTIONS;
