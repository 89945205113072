const ENV = {
  // dev: {
  //   apiUrl: 'http://localhost:3000/api/',
  //   socketUrl: 'http://localhost:3000'
  // },
  dev: {
    apiUrl: 'https://api.holper.co/api/',
    socketUrl: 'https://api.holper.co'
  },
  qa: {
    apiUrl: 'https://qa.holper.co/api/',
    socketUrl: 'https://qa.holper.co'
  },
  prod: {
    apiUrl: 'https://prod.holper.co/api/',
    socketUrl: 'https://prod.holper.co'
  }
}

function getEnvVars(env = '') {
  if (env === null || env === undefined || env === '') return ENV.dev
  if (env.indexOf('dev') !== -1) return ENV.dev
  if (env.indexOf('qa') !== -1) return ENV.qa
  if (env.indexOf('prod') !== -1) return ENV.prod
}

const envVariables = getEnvVars(process.env.REACT_APP_ENV);

export const releaseChannel = process.env.REACT_APP_ENV;

export const API_URL = envVariables.apiUrl;
export const SOCKET_URL = envVariables.socketUrl;

export const ROUTE_INCIDENTS = {
  accident: 'Tráfico accidente en la vía + 30 minutos',
  traffic: 'Tráfico pesado + 15 minutos',
  delay: 'Demora + 10 minutos'
};

export const EXTRA_TASKS_STATES = {
  accepted: 'Aceptada por el cliente',
  rejected: 'Rechazada por el cliente',
  pending: 'Pendiente aceptar/rechazar por el cliente'
};

// 'pending',
// 'onTheWay',
// 'arrivalConfirmed',
// 'inProgress',
// 'payment',
// 'paymentError',
// 'canceled',
// 'unpaid',
// 'done'

export const statesOptions = [
  { key: 'pending', value: 'pending', text: 'Pendiente' },
  { key: 'onTheWay', value: 'onTheWay', text: 'En Camino' },
  { key: 'arrivalConfirmed', value: 'arrivalConfirmed', text: 'LLegada Confirmada' },
  { key: 'inProgress', value: 'inProgress', text: 'En Progreso' },
  { key: 'payment', value: 'payment', text: 'Pendiente a Pago' },
  { key: 'canceled', value: 'canceled', text: 'Cancelada' },
  { key: 'unpaid', value: 'unpaid', text: 'No Pagada' },
  { key: 'done', value: 'done', text: 'Terminada' }
]

export const hoursOptions = [
  { key: '3', value: 3, text: '3' },
  { key: '4', value: 4, text: '4' },
  { key: '6', value: 6, text: '6' },
  { key: '8', value: 8, text: '8' }
]