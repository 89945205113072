import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Icon,
  Menu,
  Table,
  Input,
  Grid,
  Header,
  Select,
  Dropdown,
  Modal,
  Button
} from 'semantic-ui-react';
import moment from 'moment';
import { filter } from 'lodash';

import { CouponActions } from "../../modules/actions";
import ExcelHolper from '../ExcelHolper';

const ClientTable = ({ items, cities }) => {
  const dispatch = useDispatch();

  const { coupons } = useSelector(state => state.coupon)
  const [clients, setClients] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [city, setCity] = useState('');
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [selectedCoupon, setSelectedCoupon] = useState({});

  useEffect(() => {
    setClients(items);
  }, [items])

  useEffect(() => {
    if (!searchText && !city) {
      setClients(items);
    } else {
      setClients(
        filter(items, (i) => {
          return ((i.name ? i.name.toLowerCase().includes(searchText) : false) || (i.ci ? i.ci.includes(searchText) : false))
            && (city ? i.city === city : true)
        }));
    }
  }, [searchText, city, items])

  const search = (e) => {
    setSearchText(e.target.value);

    if (!e.target.value) {
      setClients(items);
    } else {
      setClients(filter(items, (i) => ((i.name ? i.name.toLowerCase().includes(e.target.value) : false) || (i.ci ? i.ci.includes(e.target.value) : false))));
    }
  }

  const assignCoupon = () => {
    dispatch(CouponActions.POST_HOLPER_COUPONS({
      userId: selectedClient.id,
      couponId: selectedCoupon.id,
      number: selectedCoupon.number
    }));

    setShowCouponModal(false);
  }

  return (
    <>
      {/* <Header as='h5' color='grey' textAlign='center'>En este apartado puedes revisar la base de datos de clientes y su estado.</Header> */}

      <Grid>
        <Grid.Row columns={3}>
          <Grid.Column floated='left'>
            <ExcelHolper items={clients} />
          </Grid.Column>
          <Grid.Column floated='right'>
            <Select
              placeholder='Ciudad'
              options={cities.map(c => ({ key: c.id, value: c.name, text: c.name }))}
              defaultValue={city}
              onChange={(e, { value }) => { setCity(value); }}
            />
          </Grid.Column>
          <Grid.Column floated='right' style={{ marginTop: 10 }}>
            <Input value={searchText} onChange={search} icon='search' placeholder='Busca por el nombre o cédula' iconPosition='left' transparent fluid />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>NOMBRE</Table.HeaderCell>
                  <Table.HeaderCell>CÉDULA</Table.HeaderCell>
                  <Table.HeaderCell>CELULAR</Table.HeaderCell>
                  <Table.HeaderCell>CORREO</Table.HeaderCell>
                  <Table.HeaderCell>CIUDAD</Table.HeaderCell>
                  <Table.HeaderCell>FECHA DE CREACIÓN</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {clients.map(c => {
                  return (
                    <Table.Row>
                      <Table.Cell>{c.name}</Table.Cell>
                      <Table.Cell>{c.ci}</Table.Cell>
                      <Table.Cell>{c.phone}</Table.Cell>
                      <Table.Cell>{c.email}</Table.Cell>
                      <Table.Cell>{c.city}</Table.Cell>
                      <Table.Cell>{moment(c.createdAt).format('DD-MM-YYYY HH:mm:ss')}</Table.Cell>
                      <Table.Cell width={2}>
                        <Dropdown item text='Acciones' onClick={() => setSelectedClient(c)}>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                dispatch(CouponActions.GET_COUPONS())
                                setShowCouponModal(true);
                              }}
                            >
                              Asignar un cupón
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='6'>
                    <Menu floated='right' pagination>
                      <Menu.Item as='a' icon>
                        <Icon name='chevron left' />
                      </Menu.Item>
                      <Menu.Item as='a'>1</Menu.Item>
                      <Menu.Item as='a'>2</Menu.Item>
                      <Menu.Item as='a'>3</Menu.Item>
                      <Menu.Item as='a'>4</Menu.Item>
                      <Menu.Item as='a' icon>
                        <Icon name='chevron right' />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal
        onClose={() => setShowCouponModal(false)}
        onOpen={() => setShowCouponModal(true)}
        open={showCouponModal}
        size='large'
      >
        <Header icon>
          Asignar un cupón al cliente {selectedClient.name}
        </Header>
        <Modal.Content>
          <Table fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Descripción</Table.HeaderCell>
                <Table.HeaderCell>Valor</Table.HeaderCell>
                <Table.HeaderCell>Tipo</Table.HeaderCell>
                <Table.HeaderCell>Fecha de Inicio</Table.HeaderCell>
                <Table.HeaderCell>Fecha de Finalización</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                coupons.map(c =>
                  <Table.Row key={c.id} onClick={() => setSelectedCoupon(c)} positive={c.id === selectedCoupon.id}>
                    <Table.Cell>{c.description}</Table.Cell>
                    <Table.Cell>{new Intl.NumberFormat().format(c.value)}</Table.Cell>
                    <Table.Cell>{c.type}</Table.Cell>
                    <Table.Cell>{moment(c.startDate).format('LLLL')}</Table.Cell>
                    <Table.Cell>{moment(c.endDate).format('LLLL')}</Table.Cell>
                  </Table.Row>
                )
              }
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red'
            inverted
            onClick={() => { setShowCouponModal(false); setSelectedCoupon({}); }}
          >
            <Icon name='remove' /> Cerrar
          </Button>
          <Button color='green' inverted disabled={!selectedClient.id || !selectedCoupon.id} onClick={() => assignCoupon()}>
            <Icon name='checkmark' /> Asignar
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default ClientTable;
