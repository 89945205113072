import { fork, all } from 'redux-saga/effects';
import createApi from '../../common/api';

import { watchUser } from './userSaga';
import { watchHiring } from './hiringSaga';
import { watchHelp } from './helpSaga';
import { watchCommon } from './commonSaga';
import { watchCoupon } from './couponSaga';

const api = createApi();

export default function* rootSaga() {
  yield all( [ 
    fork( watchUser, api ),
    fork( watchHiring, api ),
    fork( watchHelp, api ),
    fork( watchCommon, api ),
    fork( watchCoupon, api ),
  ] );
}
