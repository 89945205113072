import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Icon, Header, Tab, Table, Rating } from 'semantic-ui-react';
import { UserActions } from '../../modules/actions';
import { Loader, HolperDetailsHeader, HolperDocument } from '../../components';
import { formatAddress } from '../../common/utils';
import createApi from '../../common/api';

const api = createApi();

const HolperDetailsActive = ({ match }) => {
  const dispatch = useDispatch();
  const { isLoading, selectedUser } = useSelector(state => state.user);

  const [ratingDescriptions, setRatingDescriptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const userId = get(match, 'params.id', null);
      const filter = {
        where: { realm: 'professional' },
        include: ['address', 'addresses', 'profession', 'socialSecurity', 'education', 'bank', 'bankAccountType']
      };

      dispatch(UserActions.GET_USER({ userId, filter }));

      const { data } = await api.getHirings({
        where: {
          professionalId: userId,
          rating: { neq: '0.00' },
          state: 'done'
        },
        include: ['client'],
        order: 'date desc'
      });

      setRatingDescriptions(data);
    }

    fetchData();
  }, [dispatch, match.params.id]);  // eslint-disable-line react-hooks/exhaustive-deps


  // const updateUser = (prop, value) => {
  //   const user = {
  //     id: selectedUser.id,
  //     [prop]: value
  //   };
  //   dispatch(UserActions.UPDATE_USER(user));
  // };

  if (isLoading || !selectedUser || !selectedUser.hasOwnProperty('id')) {
    return <Loader />;
  }

  const panes = [
    {
      menuItem: 'Completa tu experiencia',
      render: () => (
        <>
          <Table basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Años de experiencia</Table.HeaderCell>
                <Table.HeaderCell>Nivel de educación</Table.HeaderCell>
                <Table.HeaderCell>Dirección de domicilo</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{selectedUser.yearsOfExperience || 'N/A'}</Table.Cell>
                <Table.Cell>{selectedUser.education ? selectedUser.education.name['es'] : 'N/A'}</Table.Cell>
                <Table.Cell>{selectedUser.address ? formatAddress(selectedUser.address.find(a => a.default)) : 'N/A'}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <br />

          <Tab.Pane attached={false}>
            <HolperDocument
              documents={[
                { key: 'laborRecommendation1', value: selectedUser.laborRecommendation1 },
                { key: 'laborRecommendation2', value: selectedUser.laborRecommendation2 },
                { key: 'basicService', value: selectedUser.basicService }
              ]}
              readOnly
            />
          </Tab.Pane>
        </>
      ),
    },
    {
      menuItem: 'Documentos de identidad',
      render: () => (
        <Tab.Pane attached={false}>
          <HolperDocument
            documents={[
              { key: 'ciFront', value: selectedUser.ciFront },
              { key: 'ciBack', value: selectedUser.ciBack },
            ]}
            readOnly
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Cuenta bancaria',
      render: () => (
        <Tab.Pane attached={false}>
          <>
            <Table basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nombre del banco</Table.HeaderCell>
                  <Table.HeaderCell>Tipo de cuenta</Table.HeaderCell>
                  <Table.HeaderCell>Número de cuenta</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>{selectedUser.bank ? selectedUser.bank.name : 'N/A'}</Table.Cell>
                  <Table.Cell>{selectedUser.bankAccountType ? selectedUser.bankAccountType.name : 'N/A'}</Table.Cell>
                  <Table.Cell>{selectedUser.accountNumber || 'N/A'}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <br />
            <HolperDocument
              documents={[
                { key: 'bankCertification', value: selectedUser.bankCertification },
              ]}
              readOnly
            />
          </>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Calificaciones',
      render: () => (
        <Tab.Pane attached={false}>
          <>
            <Table celled padded fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Cliente</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Calificación</Table.HeaderCell>
                  <Table.HeaderCell>Descripción</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {ratingDescriptions.map(r => (
                  <Table.Row>
                    <Table.Cell singleLine>
                      {r?.client?.name}
                    </Table.Cell>
                    <Table.Cell>
                      <Rating icon='star' defaultRating={r.rating} maxRating={5} disabled />
                    </Table.Cell>
                    <Table.Cell >
                      {r.ratingDescription}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
        </Tab.Pane>
      ),
    }
  ]

  return (
    <div>
      {/* <NavigationTitle label='Holpers Activos' /> */}

      <HolperDetailsHeader holper={selectedUser} />

      <br />
      <Divider horizontal>
        <Header as='h4'>
          <Icon name='file alternate outline' size='small' />
          DOCUMENTOS
        </Header>
      </Divider>
      <br />

      <Tab menu={{ pointing: true }} panes={panes} />
    </div>
  );
}

export default HolperDetailsActive;
