import React, { useState, useEffect } from 'react';
import { Icon, Input } from 'semantic-ui-react';

const EditableField = ({value, onSave, readonly}) => {
  const [val, setVal] = useState(false);
  const [editable, setEditable] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setVal(value);
  }, [value]);

  const save = () => {
    if(editable) {
      if( !val ) {
        setError(true);
      } else {
        if(val !==value) {
          onSave(val);
        }
        setEditable(false);
      }
    } else {
      setEditable(true);
    }
  }

  return (
    <Input
      transparent
      value={val}
      onChange={(e, { value }) => {
        setVal(value);
        if(error) {
          setError(false);
        }
      }}
      fluid
      disabled={!editable}
      icon={!readonly && <Icon name='edit outline' link color={error ? 'red' : editable ? 'green' : 'grey'} onClick={save} />}
    />
  );
};

export default EditableField;
