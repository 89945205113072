import React, { useEffect, useState } from "react";
import moment from "moment";
import { Table, Grid, Button, Modal, Header, Form, Label, Checkbox } from 'semantic-ui-react';
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { DateTimeInput } from 'semantic-ui-calendar-react';

import { CouponActions, CommonActions } from "../../modules/actions";

const Coupons = () => {
  const dispatch = useDispatch();

  const { coupons } = useSelector((state) => state.coupon);
  const { professions } = useSelector((state) => state.common);
  const [showAddCouponModal, setShowAddCouponModal] = useState(false);

  const [description, setDescription] = useState('');
  const [value, setValue] = useState(0);
  const [professionId, setProfessionId] = useState('')
  const [type, setType] = useState('');
  const [number, setNumber] = useState('')
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [all, setAll] = useState(false);

  useEffect(() => {
    dispatch(CouponActions.GET_COUPONS());
    dispatch(CommonActions.GET_PROFESSIONS());
  }, [dispatch])

  const validateCreateCoupon = () => {
    if (
      isEmpty(description) || 
      isEmpty(value) || 
      !professionId || 
      isEmpty(startDate) || 
      isEmpty(endDate) ||
      isEmpty(number) ||
      moment(startDate).isAfter(endDate)
    ) {
      return true;
    } else {
      return false;
    }
  }

  const createCoupon = () => {

    if (validateCreateCoupon()) return;

    const coupon = {
      description,
      value,
      professionId,
      number,
      type,
      startDate: moment(startDate).format(),
      endDate: moment(endDate).format(),
      all
    };

    dispatch(CouponActions.POST_COUPONS(coupon));

    setShowAddCouponModal(false);
  }

  return (
    <>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column floated="right">
            <Button floated="right" onClick={() => setShowAddCouponModal(true)}>Adicionar Cupón</Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Table fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Descripción</Table.HeaderCell>
                <Table.HeaderCell>Número</Table.HeaderCell>
                <Table.HeaderCell>Valor</Table.HeaderCell>
                <Table.HeaderCell>Tipo</Table.HeaderCell>
                <Table.HeaderCell>Fecha de Inicio</Table.HeaderCell>
                <Table.HeaderCell>Fecha de Finalización</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {
                coupons.map(c =>
                  <Table.Row key={c.id}>
                    <Table.Cell>{c.description}</Table.Cell>
                    <Table.Cell>{c.number}</Table.Cell>
                    <Table.Cell>{new Intl.NumberFormat().format(c.value)}</Table.Cell>
                    <Table.Cell>{c.type}</Table.Cell>
                    <Table.Cell>{moment(c.startDate).format('LLLL')}</Table.Cell>
                    <Table.Cell>{moment(c.endDate).format('LLLL')}</Table.Cell>
                  </Table.Row>
                )
              }
            </Table.Body>
          </Table>
        </Grid.Row>
      </Grid>
      <Modal
        onClose={() => setShowAddCouponModal(false)}
        onOpen={() => setShowAddCouponModal(true)}
        open={showAddCouponModal}
        size='small'
      >
        <Header icon>
          Crear Cupón
        </Header>
        <Modal.Content>
          <Form>
            <Form.Field required>
              <label>Descripción</label>
              <input
                placeholder='Descripción'
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
              {
                !isEmpty(description) ||
                <Label basic color='red' pointing>
                  La descripción es requerido.
                </Label>
              }
            </Form.Field>
            <Form.Field>
              <label>Valor</label>
              <input
                type="number"
                min={0}
                value={value}
                onChange={e => setValue(e.target.value)}
                placeholder='Valor'
              />
              {
                !isEmpty(value) ||
                <Label basic color='red' pointing>
                  El valor es requerido.
                </Label>
              }
            </Form.Field>
            <Form.Field>
              <label>Número</label>
              <input
                placeholder='Número'
                value={number}
                onChange={e => setNumber(e.target.value)}
              />
              {
                !isEmpty(number) ||
                <Label basic color='red' pointing>
                  El número es requerido.
                </Label>
              }
            </Form.Field>
            <Form.Field>
              <label>Tipo</label>
              <input
                placeholder='Tipo'
                value={type}
                onChange={e => setType(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <Form.Select
                fluid
                label='Profesión'
                options={professions.map(p => ({ key: p.id, text: p.name['es'], value: p.id }))}
                placeholder='Profesión'
                value={professionId}
                onChange={(e, { value }) => setProfessionId(value)}
              />
              {
                professionId ? null :
                  <Label basic color='red' pointing>
                    La profesión es requerido.
                  </Label>
              }
            </Form.Field>
            <Form.Field>
              <Header as='h5' style={{ marginBottom: '0.28rem', fontSize: '0.92rem' }}>Fecha de Inicio</Header>

              <DateTimeInput
                dateFormat="YYYY-MM-DD"
                localization="es"
                name="startDate"
                placeholder="Fecha de Inicio"
                value={startDate}
                iconPosition="left"
                onChange={(e, { value }) => {
                  setStartDate(value)
                }}
                popupPosition='top right'
                minDate={moment()}
              />

              {
                !isEmpty(startDate) ||
                <Label basic color='red' pointing>
                  La fecha de inicio es requerido.
                </Label>
              }

              {
                moment(startDate).isAfter(endDate) && startDate && endDate ?
                  <Label basic color='red' pointing>
                    La fecha de inicio tiene que ser antes de la fecha de terminación
                  </Label> : null
              }
            </Form.Field>

            <Form.Field>
              <Header as='h5' style={{ marginBottom: '0.28rem', fontSize: '0.92rem' }}>Fecha de Terminación</Header>
              <DateTimeInput
                dateFormat="YYYY-MM-DD"
                localization="es"
                name="endDate"
                placeholder="Fecha de Terminación"
                value={endDate}
                iconPosition="left"
                onChange={(e, { value }) => {
                  setEndDate(value)
                }}
                popupPosition='top right'
              />
              {
                !isEmpty(endDate) ||
                <Label basic color='red' pointing>
                  La fecha de terminación es requerido.
                </Label>
              }
            </Form.Field>
            <Form.Field>
              <Checkbox style={{ marginBottom: '15px' }} label='Desea asignarle un cupón a todos los clientes?' checked={all} onChange={() => setAll(!all)} />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' inverted onClick={() => setShowAddCouponModal(false)}>
            Cancelar
          </Button>
          <Button color='green' inverted onClick={createCoupon} disabled={validateCreateCoupon()}>
            Crear
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default Coupons;