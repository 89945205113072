import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { UserActions } from '../../modules/actions';
import Dashboard from '../Dashboard';
import { Loader } from '../../components';
import { publicRoutes } from '../../config/routes';

const Root = () => {
  const dispatch = useDispatch();
  const { isInitLoading, isAuthenticated } = useSelector(state => state.user);

  useEffect(() => {
    dispatch(UserActions.INIT());
  }, [dispatch]);

  if(isInitLoading) {
    return <Loader/>;
  }

  return isAuthenticated ? <Dashboard /> :
    (
      <Switch>
        { publicRoutes.map((route, index)  => <Route key={`route-public-${index}`} { ...route } />) }
      </Switch>
    );
}

export default Root;
