import React, { useState, useEffect } from 'react';
import { Icon, Menu, Table, Dropdown, Label, Form, Button, Header, Modal } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { HiringActions } from '../../modules/actions';
import { currencyFormat } from '../../common/utils';
import './styles.scss';

const hiringTableTypes = Object.freeze({
  paid: 'paid',
  notPaid: 'notPaid',
})

const HiringPaymentTable = ({ items, type }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [paginationCount, setPaginationCount] = useState(1);
  const [paginationIndex, setPaginationIndex] = useState(1);
  const [showPayModal, setShowPayModal] = useState(false);
  const [selectedDebt, setSelectedDebt] = useState();
  const [description, setDescription] = useState();
  const [file, setFile] = useState();

  useEffect(() => {
    setPaginationCount(Math.ceil(items.length / 10));
  }, [items]);

  const renderPaginationIndexes = () => {
    const indexes = [];

    for (let i = 0; i < paginationCount; i++) {
      indexes.push(<Menu.Item disabled={paginationIndex === i + 1} key={`active-${i}`} as='a' onClick={() => setPaginationIndex(i + 1)}>{i + 1}</Menu.Item>);
    }

    return indexes;
  };

  const pay = () => {
    dispatch(HiringActions.POST_PAY({ id: selectedDebt.id, description, file }));

    setShowPayModal(false);
  }

  const fileChange = (e) => {
    setFile(e.target.files[0]);
  }

  return (
    <>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>HOLPER</Table.HeaderCell>
            <Table.HeaderCell>TELÉFONO</Table.HeaderCell>
            <Table.HeaderCell>CORREO</Table.HeaderCell>
            <Table.HeaderCell>FECHA</Table.HeaderCell>
            <Table.HeaderCell>FECHA DE VENCIMIENTO</Table.HeaderCell>
            <Table.HeaderCell>MONTO A PAGAR</Table.HeaderCell>
            <Table.HeaderCell>FECHA DE PAGO</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map((hiringDebt) => (
            <Table.Row key={hiringDebt.id}>
              <Table.Cell>{hiringDebt.user ? hiringDebt.user.name : 'No disponible'}</Table.Cell>
              <Table.Cell>{hiringDebt.user ? hiringDebt.user.phone : 'No disponible'}</Table.Cell>
              <Table.Cell>{hiringDebt.user ? hiringDebt.useremail : 'No disponible'}</Table.Cell>
              <Table.Cell>{moment(hiringDebt.createdAt).format('DD-MM-YYYY')}</Table.Cell>
              <Table.Cell>{moment(hiringDebt.createdAt).add(15, 'days').format('DD-MM-YYYY')}</Table.Cell>
              <Table.Cell>{currencyFormat(hiringDebt.value)}</Table.Cell>
              <Table.Cell>{hiringDebt.paymentDate ? moment(hiringDebt.paymentDate).format('DD-MM-YYYY') : ''}</Table.Cell>
              <Table.Cell>
                <Dropdown item text='Acciones' onClick={() => setSelectedDebt(hiringDebt)}>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        history.push(`/payments/${hiringDebt.id}/details`)
                      }}
                    >
                      Detalles
                    </Dropdown.Item>

                    {type === 'notPaid' ||
                      <Dropdown.Item
                        onClick={() => { setShowPayModal(true) }}
                      >
                        Pagar
                      </Dropdown.Item>}

                  </Dropdown.Menu>
                </Dropdown>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            {/* <Table.HeaderCell colSpan='12'>
              <Menu floated='right' pagination>
                <Menu.Item as='a' icon disabled={paginationIndex === 1}>
                  <Icon name='chevron left' />
                </Menu.Item>
                {renderPaginationIndexes()}
                <Menu.Item as='a' icon disabled={paginationIndex === paginationCount || !paginationCount}>
                  <Icon name='chevron right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell> */}
          </Table.Row>
        </Table.Footer>
      </Table>

      <Modal
        onClose={() => setShowPayModal(false)}
        onOpen={() => setShowPayModal(true)}
        open={showPayModal}
        size='small'
      >
        <Header icon>
          Pagar
        </Header>
        <Modal.Content>
          <Form>
            <Form.Field required>
              <label>Descripción</label>
              <input
                placeholder='Descripción'
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
              {
                !isEmpty(description) ||
                <Label basic color='red' pointing>
                  La descripción es requerido.
                </Label>
              }
            </Form.Field>

            <Form.Field>
              <label>Carga el comprobante </label>
              <Button as="label" htmlFor="file" type="button" animated="fade">
                <Button.Content visible>
                  <Icon name="file" />
                </Button.Content>
                <Button.Content hidden>Cargar</Button.Content>
              </Button>
              <input
                type="file"
                id="file"
                hidden
                onChange={fileChange}
              />

              <Form.Input
                fluid
                label="Archivo Cargado "
                placeholder=""
                readOnly
                value={file ? file.name : 'Nombre del Archivo'}
              />
              {
                !file &&
                <Label basic color='red' pointing>
                  El comprobante es requerido.
                </Label>
              }
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' inverted onClick={() => setShowPayModal(false)}>
            Cancelar
          </Button>
          <Button color='green' inverted onClick={pay} disabled={!description || !file}>
            Pagar
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

HiringPaymentTable.protoType = {
  type: PropTypes.oneOf([
    hiringTableTypes.paid,
    hiringTableTypes.notPaid,
  ]),
  items: PropTypes.array
};

export default HiringPaymentTable;
