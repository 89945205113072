import React, { useState, useEffect } from 'react';
import { Icon, Menu, Table, Button, Label, Segment, Grid, Select } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { filter, isEmpty } from 'lodash';
import moment from 'moment';
import { DateInput } from 'semantic-ui-calendar-react';
import PropTypes from 'prop-types';
import { getHiringStateName, calcHiringPrices } from '../../common/utils';
import './styles.scss';

const hiringTableTypes = Object.freeze({
  progress: 'progress',
  unsatisfied: 'unsatisfied',
  scheduled: 'scheduled',
  completed: 'completed'
});

const HiringTable = ({ items, type, cities }) => {
  const [paginationCount, setPaginationCount] = useState(1);
  const [paginationIndex, setPaginationIndex] = useState(1);
  const [hirings, setHirings] = useState([]);
  const [filterDate, setFilterDate] = useState();
  const [city, setCity] = useState('');

  useEffect(() => {
    setHirings(items);
    setPaginationCount(Math.ceil(items.length / 10));
  }, [items]);

  useEffect(() => {
    if (!city && !filterDate) {
      setHirings(items);
    } else if (city && !filterDate) {
      setHirings(filter(items, (i) => (city ? i.city === city : true)));
    } else if (!city && filterDate) {
      setHirings(filter(items, (i) => (moment(i.date).isBetween(moment(filterDate).startOf('day'), moment(filterDate).endOf('day')))));
    } else {
      setHirings(filter(items, (i) => (
        moment(i.date).isBetween(moment(filterDate).startOf('day'), moment(filterDate).endOf('day')) &&
        (city ? i.city === city : true)
      )));
    }
  }, [city, filterDate, items])

  const renderPaginationIndexes = () => {
    const indexes = [];

    for (let i = 0; i < paginationCount; i++) {
      indexes.push(<Menu.Item disabled={paginationIndex === i + 1} key={`active-${i}`} as='a' onClick={() => setPaginationIndex(i + 1)}>{i + 1}</Menu.Item>);
    }

    return indexes;
  };

  const getRowBadge = (hiring) => {
    switch (type) {
      case hiringTableTypes.progress:
        return null;
      case hiringTableTypes.unsatisfied:
        return () => {
          if (hiring.problem) {
            return <Label color='red' ribbon />;
          }

          if (hiring.state === 'pending' && moment().diff(moment(hiring.date), 'minutes') > 1) {
            return <Label color='purple' ribbon />;
          }
        };
      case hiringTableTypes.scheduled:
        return null;
      case hiringTableTypes.completed:
        return <Label color={hiring.state === 'canceled' ? 'pink' : 'green'} ribbon />;
      default:
        return null;
    }
  };
  const getTableLegend = () => {
    switch (type) {
      case hiringTableTypes.progress:
        return null;
      case hiringTableTypes.unsatisfied:
        return (
          <>
            <Label empty color='purple'>No se ha iniciado</Label>
            <Label empty color='red'>Insatisfecho</Label>
          </>
        );
      case hiringTableTypes.scheduled:
        return (
          <>
            <Label empty color='blue'>En tiempo</Label>
            <Label empty color='orange'>Próximo a iniciar</Label>
          </>
        );
      case hiringTableTypes.completed:
        return (
          <>
            <Label empty color='green'>Completado</Label>
            <Label empty color='pink'>Cancelado</Label>
          </>
        );
      default:
        return null;
    }
  };

  const getRescheduledProfessional = (hiring) => {
    if (hiring?.professional) {
      if (['favorites'].includes(hiring.holperSelected)) {
        return `${hiring?.professional?.name} (F)`;
      }

      if (['choose', 'rescheduled'].includes(hiring.holperSelected)) {
        return hiring?.professional?.name;
      }
    } else if (!isEmpty(hiring.reschedulingProfessionalUsers)) {
      return hiring.reschedulingProfessionalUsers.map(rUser => rUser.name).join(' - ');
    } else {
      return '';
    }
  }

  return (
    <>
      {cities.length ? <Grid>
        <Grid.Row columns={4}>
          <Grid.Column>
            <Select
              placeholder='Ciudad'
              options={cities.map(c => ({ key: c.id, value: c.name, text: c.name }))}
              defaultValue={city}
              onChange={(e, { value }) => { setCity(value); }}
            />
          </Grid.Column>

          <Grid.Column>
            <DateInput
              dateFormat="YYYY-MM-DD"
              localization="es"
              name="filterDate"
              placeholder="Fecha"
              value={filterDate}
              iconPosition="left"
              onChange={(e, { value }) => {
                setFilterDate(value)
              }}
              popupPosition='top right'
            // minDate={moment()}
            />
          </Grid.Column>

          <Grid.Column>
            <div style={{ fontSize: 20, marginTop: 10 }}>Total de Contrataciones:  <strong style={{ fontSize: 20 }}>{hirings.length}</strong></div>

          </Grid.Column>
        </Grid.Row>
      </Grid> : null}

      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>CLIENTE</Table.HeaderCell>
            <Table.HeaderCell>HOLPER</Table.HeaderCell>
            {/* <Table.HeaderCell>SERVICIO</Table.HeaderCell> */}
            <Table.HeaderCell>FECHA</Table.HeaderCell>
            <Table.HeaderCell>HORA</Table.HeaderCell>
            {type === 'canceled' && <Table.HeaderCell>FECHA DE CANCELACIÓN</Table.HeaderCell>}
            <Table.HeaderCell>MONTO</Table.HeaderCell>
            <Table.HeaderCell>CIUDAD</Table.HeaderCell>
            <Table.HeaderCell>ESTADO/RATING</Table.HeaderCell>
            <Table.HeaderCell>HOLPER REAGENDADO</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {hirings.map(hiring => (
            <Table.Row key={hiring.id}>
              <Table.Cell collapsing>
                {getRowBadge(hiring)}
              </Table.Cell>
              <Table.Cell>{hiring.client ? hiring.client.name : 'No disponible'}</Table.Cell>
              <Table.Cell>{hiring.professional ? <NavLink to={`/holpers/${hiring?.professionalId}/active`} target="_blank"> {`${hiring?.professional?.name} (${hiring?.professional?.rating})`} </NavLink> : 'No disponible'}</Table.Cell>
              {/* <Table.Cell>{hiring.profession.name['es']}</Table.Cell> */}
              <Table.Cell>{moment(hiring.date).format('DD/MM/YYYY')}</Table.Cell>
              <Table.Cell>{moment(hiring.date).format('hh:mm a')}</Table.Cell>
              {type === 'canceled' && <Table.Cell>{hiring.canceledDate ? moment(hiring.canceledDate).format('DD/MM/YYYY hh:mm a') : ''}</Table.Cell>}
              <Table.Cell>{calcHiringPrices(hiring).total}</Table.Cell>
              <Table.Cell>{hiring.city}</Table.Cell>
              <Table.Cell>{`${getHiringStateName(hiring.state)} ${hiring?.rating ? `/ (${hiring?.rating})` : ''}`}</Table.Cell>
              <Table.Cell>{getRescheduledProfessional(hiring)}</Table.Cell>

              <Table.Cell>
                <Button animated='fade' basic circular as={NavLink} to={`/hirings/${hiring.id}/${type}`}>
                  <Button.Content visible>Ver más</Button.Content>
                  <Button.Content hidden>
                    <Icon name='eye' />
                  </Button.Content>
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          {/* <Table.Row>
            <Table.HeaderCell colSpan='12'>
              <Menu floated='right' pagination>
                <Menu.Item as='a' icon disabled={paginationIndex === 1}>
                  <Icon name='chevron left' />
                </Menu.Item>
                {renderPaginationIndexes()}
                <Menu.Item as='a' icon disabled={paginationIndex === paginationCount || !paginationCount}>
                  <Icon name='chevron right' />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row> */}
        </Table.Footer>
      </Table>

      <Segment floated='right' basic>
        {getTableLegend()}
      </Segment>
    </>
  );
}

HiringTable.protoType = {
  type: PropTypes.oneOf([
    hiringTableTypes.progress,
    hiringTableTypes.unsatisfied,
    hiringTableTypes.scheduled,
    hiringTableTypes.completed
  ]),
  items: PropTypes.array
};

export default HiringTable;
