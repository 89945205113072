import React  from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { privateRoutes } from '../../config/routes';
import { Header, Loader, Sidebar } from '../../components';

const Dashboard = () => {
  const { isInitLoading, authenticatedUser } = useSelector(state => state.user);

  if(isInitLoading || !authenticatedUser) {
    return <Loader/>;
  }

  return (
    <div>
      <Header/>

      <Sidebar content={(
        <>
          {/* <StatCard stats={stats} /> */}
          <Switch>
            { privateRoutes.map((route, index)  => <Route key={`route-${index}`} { ...route } />) }
          </Switch>
        </>
      )} />
    </div>
  );
};

export default Dashboard;
