import React, { useState } from 'react';
import {
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
  Table,
  Label,
  Rating,
  Button,
  Select,
  Form,
  Modal as SModal
} from 'semantic-ui-react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UserActions, CommonActions } from '../../modules/actions';
import { formatAddress, showAvatar } from '../../common/utils';
import { isEmpty } from 'lodash';
import EditableField from '../EditableField';
import Modal from '../Modal';
import './styles.scss';

const HolperDetailsHeader = ({ holper, history }) => {
  const dispatch = useDispatch();
  const [showToggleDisableModal, setShowToggleDisableModal] = useState(false);
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [disableOption, setDisableOption] = useState(1);
  const [file, setFile] = useState();

  const disableOptions = [
    { key: '1', value: 1, text: '7 días' },
    { key: '2', value: 2, text: '14 días' },
    { key: '3', value: 3, text: 'Indefinido' }
  ]

  const updateUser = (prop, value) => {
    let user;

    if (prop === 'phone') {
      user = {
        id: holper.id,
        phone: value,
        username: value
      };
    } else {
      user = {
        id: holper.id,
        [prop]: value
      };
    }

    dispatch(UserActions.UPDATE_USER(user, false));
  };

  const updateAvatar = async () => {
    const fileName = `${new Date().getTime()}.jpg`;

    const user = {
      id: holper.id,
      avatar: fileName
    };

    dispatch(CommonActions.UPLOAD_FILE({ data: file, container: 'holper-profile-images', name: fileName }));
    dispatch(UserActions.UPDATE_USER(user, false));
  }

  const toggleDisable = async () => {
    setShowToggleDisableModal(false);

    let disabledDate = moment().add(100, 'years').utc().format();

    if (holper.enabled) {
      switch (disableOption) {
        case 1:
          disabledDate = moment().add(1, 'week').utc().format();
          break;
        case 2:
          disabledDate = moment().add(2, 'weeks').utc().format();
          break;
        default:
          break;
      }
    }

    const user = {
      id: holper.id,
      enabled: !holper.enabled,
      disabledDate,
      available: !holper.enabled
    };

    await dispatch(UserActions.UPDATE_USER(user, false));
    setTimeout(() => history.push('/Holpers'), 500);
  };

  const fileChange = (e) => {
    setFile(e.target.files[0]);
  }

  return (
    <Segment>
      <Grid columns={2} relaxed='very'>
        <Grid.Column textAlign='center' verticalAlign='middle'>
          <Image
            className='header-image'
            src={showAvatar(holper)}
            bordered
            circular
            size='small'
            verticalAlign='middle'
            onError={i => i.target.src = `${showAvatar({})}`}
          />
          <Header icon textAlign='center'>
            <Header.Content as='h2'>{holper.name}</Header.Content>
            <Header.Subheader>
              <Rating maxRating={5} icon='star' size='mini' disabled rating={holper.rating} clearable={false} />
            </Header.Subheader>
            <Header as='h3'>{`Calificación Promedio - (${holper.rating})`}</Header>
            <Header.Subheader>{holper.hiringCount} contrataciones</Header.Subheader>
          </Header>

          <Button color='green' onClick={() => setShowAvatarModal(true)}>
            <Icon name='photo' />
            Cambiar Foto
          </Button>

          {holper.enabled ? (
            <Button color='red' onClick={() => setShowToggleDisableModal(true)}>
              <Icon name='ban' />
              Suspender cuenta
            </Button>
          ) : (
            <Button color='green' onClick={() => setShowToggleDisableModal(true)}>
              <Icon name='thumbs up' />
              Habilitar cuenta
            </Button>
          )}

        </Grid.Column>
        <Grid.Column textAlign='center' verticalAlign='middle'>
          <Divider horizontal>
            <Header as='h4'>
              <Icon name='address card outline' />
              Detalles de la cuenta
            </Header>
          </Divider>

          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>Nombre:</Table.Cell>
                <Table.Cell>
                  <EditableField value={holper.name} onSave={(value) => updateUser('name', value)} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>C.I:</Table.Cell>
                <Table.Cell>
                  <EditableField value={holper.ci} onSave={(value) => updateUser('ci', value)} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Fecha de ingreso:</Table.Cell>
                <Table.Cell>
                  <EditableField value={moment(holper.createdAt).format('DD/MM/YYYY')} readonly />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>E-mail:</Table.Cell>
                <Table.Cell>
                  <EditableField value={holper.email} onSave={(value) => updateUser('email', value)} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Teléfono:</Table.Cell>
                <Table.Cell>
                  <EditableField value={holper.phone} onSave={(value) => updateUser('phone', value)} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Dirección:</Table.Cell>
                <Table.Cell>
                  <EditableField value={!isEmpty(holper.addresses) ? formatAddress(holper.addresses.find(a => a.default)) : ''} onSave={(value) => updateUser('address', value)} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Servicios:</Table.Cell>
                <Table.Cell>
                  <Label color='grey' circular>{holper.profession ? holper.profession.name['es'] : 'N/A'}</Label></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>

      <Divider vertical>
        <Icon name='user circle' color='grey' />
      </Divider>

      <Modal
        size='tiny'
        visible={showToggleDisableModal}
        title={`${holper.enabled ? 'Suspender' : 'Habilitar'} cuenta`}
        description={`Desea ${holper.enabled ? 'suspender' : 'habilitar'} la cuenta de ${holper.name}`}
        cancelText='Cancelar'
        acceptText='Aceptar'
        onCancel={() => setShowToggleDisableModal(false)}
        onAccept={toggleDisable}
      >
        {holper.enabled ? (
          <Form>
            <Form.Field>
              {Number(holper.disabledCount) >= 3 && <h1 style={{ color: 'red' }}>Esta cuenta esta deshabilitada indefinidamente porque ya fue suspendida el máximo de 3 veces.</h1>}

              <label>Por cuánto tiempo desea suspender la cuenta:</label>
              <Select
                placeholder='Período de suspensión'
                options={disableOptions}
                defaultValue={disableOption}
                onChange={(e, { value }) => setDisableOption(value)}
              />
            </Form.Field>
          </Form>
        ) : <></>}
      </Modal>

      <SModal
        onClose={() => setShowAvatarModal(false)}
        onOpen={() => setShowAvatarModal(true)}
        open={showAvatarModal}
        size='small'
      >
        <Header icon>
          Cambiar Foto Holper
        </Header>
        <SModal.Content>
          <Form>
            <Form.Field>
              <label>Carga la foto </label>
              <Button as="label" htmlFor="file" type="button" animated="fade">
                <Button.Content visible>
                  <Icon name="file" />
                </Button.Content>
                <Button.Content hidden>Cargar</Button.Content>
              </Button>
              <input
                type="file"
                id="file"
                hidden
                onChange={fileChange}
              />

              <Form.Input
                fluid
                label="Archivo Cargado "
                placeholder=""
                readOnly
                value={file ? file.name : 'Nombre del Archivo'}
              />
              {
                !file &&
                <Label basic color='red' pointing>
                  La foto es requerida.
                </Label>
              }
            </Form.Field>
          </Form>
        </SModal.Content>
        <SModal.Actions>
          <Button color='red' inverted onClick={() => setShowAvatarModal(false)}>
            Cancelar
          </Button>
          <Button color='green' inverted onClick={updateAvatar} disabled={!file}>
            Subir Foto
          </Button>
        </SModal.Actions>
      </SModal>
    </Segment>
  )
};

export default withRouter(HolperDetailsHeader);
