import React, { useEffect, useState, forwardRef } from "react";

import { Button, Input, Grid, Modal as SModal } from "semantic-ui-react";
import { get } from "lodash";
import moment from "moment";

import DatePickerInput from "../DatePicker";

const HiringValueAddModal = ({ isOpen, onSave, onCancel, hiringValue, type }) => {
  const [selectedDate, setSelectedDate] = useState();
  const [value, setValue] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (type === 'edit') {
      const newDate = get(hiringValue, 'date', moment().format('YYYY-MM-DD'));

      setSelectedDate(moment(newDate).toDate());
      setValue(hiringValue?.value);
      setDescription(hiringValue?.description);
    } else {
      setSelectedDate('');
      setValue('');
      setDescription('');
    }
  }, [hiringValue, type]);

  return <SModal
    size={'small'}
    open={isOpen}
  >
    <SModal.Header>{type === 'add' ? 'Adicionar Precio' : 'Editar Precio'}</SModal.Header>
    <SModal.Content>
      <Grid columns="3">
        <Grid.Row>
          <Grid.Column>
            <DatePickerInput
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
            />

          </Grid.Column>
          <Grid.Column>
            <Input type="number" value={value} onChange={(e) => setValue(e.target.value)} placeholder="Precio"></Input>
          </Grid.Column>
          <Grid.Column>
            <Input value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Descripción"></Input>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </SModal.Content>
    <SModal.Actions>
      <Button negative onClick={onCancel}>
        Cancelar
      </Button>
      <Button
        positive
        disabled={!selectedDate || !value || !description}
        onClick={() => onSave({ date: selectedDate, value, description })}
      >
        {type === 'edit' ? 'Editar' : 'Adicionar'}
      </Button>
    </SModal.Actions>
  </SModal>
}

export default HiringValueAddModal;