import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Icon,
  Menu,
  Table,
  Grid,
  Checkbox,

} from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { DateInput } from 'semantic-ui-calendar-react';

import { calcHiringPrices } from '../../common/utils';
import { HiringActions } from "../../modules/actions";

const HiringConfirmationsTable = () => {
  const dispatch = useDispatch();

  const { hiringConfirmations } = useSelector(state => state.hiring);
  const [selectedHiringConfirmation, setSelectedHiringConfirmation] = useState(null);
  const [filterDate, setFilterDate] = useState(moment().toDate());
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    const filter = {
      where: {
        and: [
          { state: 'pending' },
          { professionalId: { neq: null } },
          { confirmed: confirmed || false },
          { date: { gte: moment(filterDate).startOf('day').toDate() } },
          { date: { lte: moment(filterDate).endOf('day').toDate() } }
        ],
      },
      include: [
        'professional',
        'client',
        'hiringExtraTasks',
        'holperReplacement'
      ],
      order: 'createdAt DESC'
    };

    dispatch(HiringActions.GET_HIRING_CONFIRMATIONS(filter));
  }, [confirmed, filterDate, dispatch]);

  return (
    <>
      <Grid>
        <Grid.Row columns={4}>
          <Grid.Column>
            <DateInput
              dateFormat="YYYY-MM-DD"
              localization="es"
              name="filterDate"
              placeholder="Fecha"
              value={filterDate}
              iconPosition="left"
              onChange={(e, { value }) => {
                setFilterDate(value);
              }}
              popupPosition='top right'
            // minDate={moment()}
            />
          </Grid.Column>

          <Grid.Column floated='left' width={4}>
            <Checkbox
              style={{ marginTop: '10px' }}
              label='No Confirmados'
              checked={!confirmed}
              onChange={() => setConfirmed(!confirmed)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>CLIENTE</Table.HeaderCell>
                  <Table.HeaderCell>HOLPER</Table.HeaderCell>
                  <Table.HeaderCell>FECHA</Table.HeaderCell>
                  <Table.HeaderCell>HORA</Table.HeaderCell>
                  <Table.HeaderCell>MONTO</Table.HeaderCell>
                  <Table.HeaderCell>CIUDAD</Table.HeaderCell>
                  <Table.HeaderCell>REEMPLAZO</Table.HeaderCell>
                  <Table.HeaderCell>CONFIRMADO</Table.HeaderCell>
                  <Table.HeaderCell>ACCIONES</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {hiringConfirmations.map(c => {
                  return (
                    <Table.Row key={c.id} positive={c.confirmed} negative={!c.confirmed}>
                      <Table.Cell >{c?.client?.name}</Table.Cell>
                      {/* <Table.Cell>{c?.professional?.name}</Table.Cell> */}
                      <Table.Cell><NavLink to={`/holpers/${c?.professionalId}/active`}>{c?.professional?.name}</NavLink></Table.Cell>

                      <Table.Cell>{moment(c.date).format('DD/MM/YYYY')}</Table.Cell>
                      <Table.Cell>{moment(c.date).format('hh:mm a')}</Table.Cell>
                      <Table.Cell>{calcHiringPrices(c).total}</Table.Cell>
                      <Table.Cell>{c?.city}</Table.Cell>
                      <Table.Cell>{c.holperReplacementId !== 'pending' ? <NavLink to={`/holpers/${c?.holperReplacementId}/active`}>{c?.holperReplacement?.name}</NavLink> : 'Pendinte'}</Table.Cell>
                      <Table.Cell>{c.confirmed ? 'Si' : 'No'}</Table.Cell>
                      <Table.Cell width={2}>
                        {/* {!c.paid && <Dropdown item text='Acciones' onClick={() => setSelectedHiringConfirmation(c)}>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                // dispatch(UserActions.UPDATE_CLIENT_DEBTS({ id: c.id, data: { paid: true } }));
                              }}
                            >
                              Pagar
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>} */}

                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>

              {/* <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='6'>
                    <Menu floated='right' pagination>
                      <Menu.Item as='a' icon>
                        <Icon name='chevron left' />
                      </Menu.Item>
                      <Menu.Item as='a'>1</Menu.Item>
                      <Menu.Item as='a'>2</Menu.Item>
                      <Menu.Item as='a'>3</Menu.Item>
                      <Menu.Item as='a'>4</Menu.Item>
                      <Menu.Item as='a' icon>
                        <Icon name='chevron right' />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer> */}
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default HiringConfirmationsTable;
