import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import store, { history } from './config/store';
import Root from './views/Root';

import 'moment/locale/es';
import "react-datepicker/dist/react-datepicker.css";

import es from 'date-fns/locale/es';
registerLocale('es', es)

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Root/>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
