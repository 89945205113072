import { takeLatest, put, call, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { HiringActions } from '../actions';

export function* getHirings(api, { payload: { filter } }) {
  try {
    const { ok, status, data } = yield call(api.getHirings, filter);

    if (ok && status === 200) {
      yield put(HiringActions.GET_HIRINGS_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.GET_HIRINGS_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* getHiring(api, { payload: { hiringId } }) {
  try {
    const { ok, status, data } = yield call(api.getHiring, hiringId);

    if (ok && status === 200) {
      yield put(HiringActions.GET_HIRING_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.GET_HIRING_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* getHiringDeletedIncluded(api, { payload: { hiringId } }) {
  try {
    const { ok, status, data } = yield call(api.getHiringDeletedIncluded, hiringId);

    if (ok && status === 200) {
      yield put(HiringActions.GET_HIRING_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.GET_HIRING_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* patchHiring(api, { payload: { data } }) {
  try {
    const { ok, status } = yield call(api.patchHiring, data);

    if (ok && status === 200) {
      yield put(HiringActions.GET_HIRING({ hiringId: data.id }));
      yield put(HiringActions.PATCH_HIRING_SUCCESS());

      const statesFilter = ['pending', 'onTheWay', 'arrivalConfirmed', 'inProgress'];

      yield call(getHirings, api, {
        payload: {
          filter: {
            where: { state: { inq: statesFilter }},
            isDeleted: true, include: [
              { professional: ['bankAccountType', 'bank'] },
              'client',
              'profession',
              'hiringExtraTasks'
            ], order: "createdAt DESC"
          }
        }
      });
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.PATCH_HIRING_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* patchHirings(api, { payload: { data } }) {
  try {
    const results = yield all(data.map(hiring => api.patchHiring(hiring)));
    const checkedResults = results.map(({ ok, status }) => ok && status === 200);

    if (checkedResults.every(result => result)) {
      yield put(HiringActions.GET_HIRINGS_SUCCESS());
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.PATCH_HIRINGS_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* postHiringClose(api, { payload: { hiringId, cash } }) {
  try {
    const { ok, status } = yield call(api.postHiringClose, hiringId, cash);

    if (ok && status === 200) {
      yield put(HiringActions.POST_HIRING_CLOSE_SUCCESS());
      yield put(push('/hirings'));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.POST_HIRING_CLOSE_ERROR(e));
  }
}

export function* postHiringCancel(api, { payload: { hiringId } }) {
  try {
    const { ok, status } = yield call(api.postHiringCancel, hiringId);

    if (ok && status === 200) {
      yield put(HiringActions.POST_HIRING_CANCEL_SUCCESS());
      yield put(push('/hirings'));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.POST_HIRING_CANCEL_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* postHiringEnable(api, { payload: { id, professionalId, date } }) {
  try {
    const { ok, status } = yield call(api.hiringEnable, id, professionalId, date);

    if (ok && status === 200) {
      yield put(HiringActions.POST_HIRING_ENABLE_SUCCESS());
      yield put(push('/hirings'));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.POST_HIRING_ENABLE_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* postHiringResend(api, { payload: { id } }) {
  try {
    const { ok, status } = yield call(api.resendHiring, id);

    if (ok && status === 200) {
      yield put(HiringActions.POST_HIRING_RESEND_SUCCESS());
      yield put(push('/hirings'));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.POST_HIRING_RESEND_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* getHiringDebts(api, { payload: filter }) {
  try {
    const { ok, status, data } = yield call(api.getHiringDebts, filter);

    if (ok && status === 200) {
      yield put(HiringActions.GET_HIRING_DEBTS_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.GET_HIRING_DEBTS_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* getHiringDebt(api, { payload: { id, filter } }) {
  try {
    const { ok, status, data } = yield call(api.getHiringDebt, id, filter);

    if (ok && status === 200) {
      yield put(HiringActions.GET_HIRING_DEBT_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.GET_HIRING_DEBT_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* patchHiringDebt(api, { payload }) {
  try {
    const fileStatus = yield call(api.uploadFile, payload.file, 'holper-payment');

    const { ok, status } = yield call(
      api.updateHiringDebts,
      payload.id,
      { description: payload.description, paid: payload.paid, image: `${new Date().getTime()}-${payload.file.name}`, paymentDate: payload.paymentDate });

    if (ok && status === 200 && fileStatus.ok) {
      yield put(HiringActions.UPDATE_HIRING_DEBT_SUCCESS());
      yield call(getHiringDebts, api, {
        payload: {
          filter: {
            include: [
              { hiring: ['hiringExtraTasks', 'coupon', 'professional'] },
            ]
          }
        }
      });
    } else {
      throw new Error('Server Error');
    }
  } catch (e) {
    yield put(HiringActions.UPDATE_HIRING_DEBT_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* postPay(api, { payload: { id, description, file } }) {
  const fileName = `${new Date().getTime()}-${file.name}`;
  const fileStatus = yield call(api.uploadFile, file, 'holper-payment', fileName);

  try {
    if (fileStatus.ok) {
      const { ok, status } = yield call(api.postPay, id, description, fileName);

      if (ok && status === 200) {
        yield put(HiringActions.POST_PAY_SUCCESS());

        yield call(getHiringDebts, api, {
          payload: {
            filter: {
              include: ['user'],
              order: 'createdAt'
            }
          }
        });
      } else {
        throw new Error('Server Error');
      }

    }
  } catch (e) {
    yield put(HiringActions.POST_PAY_ERROR(e));
  }
}

export function* getHiringConfirmations(api, { payload: filter }) {
  try {
    const { ok, status, data } = yield call(api.getHirings, filter);

    if (ok && status === 200) {
      yield put(HiringActions.GET_HIRING_CONFIRMATIONS_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.GET_HIRING_CONFIRMATIONS_ERROR(e));
  }
}


export function* getHiringValues(api) {
  try {
    const { ok, status, data } = yield call(api.getHiringValues);

    if (ok && status === 200) {
      yield put(HiringActions.GET_HIRING_VALUES_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.GET_HIRING_VALUES_ERROR(e));
  }
}

export function* postHiringValue(api, { payload }) {
  try {
    const { ok, status } = yield call(api.postHiringValue, payload);

    if (ok && status === 200) {
      yield put(HiringActions.POST_HIRING_VALUE_SUCCESS());
      yield call(getHiringValues, api);
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.POST_HIRING_VALUE_ERROR(e));
  }
}

export function* updateHiringValue(api, { payload }) {
  try {
    const { ok, status } = yield call(api.updateHiringValue, payload.id, payload.data);

    if (ok && status === 200) {
      yield put(HiringActions.PATCH_HIRING_VALUE_SUCCESS());
      yield call(getHiringValues, api);
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.PATCH_HIRING_VALUE_ERROR(e));
  }
}

export function* deleteHiringValue(api, { payload }) {
  try {
    const { ok, status } = yield call(api.deleteHiringValue, payload);

    if (ok && status === 200) {
      yield put(HiringActions.DELETE_HIRING_VALUE_SUCCESS());
      yield call(getHiringValues, api);
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(HiringActions.DELETE_HIRING_VALUE_ERROR(e));
  }
}

export function* watchHiring(api) {
  yield takeLatest('GET_HIRINGS', getHirings, api);
  yield takeLatest('GET_HIRING', getHiring, api);
  yield takeLatest('GET_HIRING_DELETED_INCLUDED', getHiringDeletedIncluded, api);
  yield takeLatest('PATCH_HIRING', patchHiring, api);
  yield takeLatest('PATCH_HIRINGS', patchHirings, api);
  yield takeLatest('POST_HIRING_CLOSE', postHiringClose, api);
  yield takeLatest('POST_HIRING_CANCEL', postHiringCancel, api);
  yield takeLatest('POST_HIRING_ENABLE', postHiringEnable, api);
  yield takeLatest('POST_HIRING_RESEND', postHiringResend, api);
  yield takeLatest('GET_HIRING_DEBTS', getHiringDebts, api);
  yield takeLatest('GET_HIRING_DEBT', getHiringDebt, api);
  yield takeLatest('GET_HIRING_CONFIRMATIONS', getHiringConfirmations, api);
  yield takeLatest('UPDATE_HIRING_DEBT', patchHiringDebt, api);
  yield takeLatest('POST_PAY', postPay, api);
  yield takeLatest('GET_HIRING_VALUES', getHiringValues, api);
  yield takeLatest('POST_HIRING_VALUE', postHiringValue, api);
  yield takeLatest('PATCH_HIRING_VALUE', updateHiringValue, api);
  yield takeLatest('DELETE_HIRING_VALUE', deleteHiringValue, api);

}
