import React from "react";

import ClientDebtsTable from "../../components/ClientDebtsTable";

const ClientsDebts = () => {
  return <>
    <ClientDebtsTable />
  </>;
}

export default ClientsDebts;