import { handleActions } from 'redux-actions';
import { UserActions as ACTIONS } from '../actions';

const initialState = {
  authenticatedUser: {},
  selectedUser: {},
  isAuthenticated: false,
  isLoading: false,
  isInitLoading: true,
  apiError: '',
  professionals: [],
  clients: [],
  clientDebts: []
};

export const userReducer = handleActions(
  {
    [ACTIONS.INIT]: (state) => {
      return {
        ...state,
        isInitLoading: true
      };
    },
    [ACTIONS.INIT_SUCCESS]: (state, { payload: authenticatedUser }) => {
      return {
        ...state,
        authenticatedUser,
        isAuthenticated: true,
        isInitLoading: false
      };
    },
    [ACTIONS.INIT_ERROR]: (state) => {
      return {
        ...state,
        isAuthenticated: false,
        isInitLoading: false
      };
    },
    [ACTIONS.LOGIN]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.LOGIN_SUCCESS]: (state, { payload: authenticatedUser }) => {
      return {
        ...state,
        authenticatedUser,
        isAuthenticated: true,
        isLoading: false
      };
    },
    [ACTIONS.LOGIN_ERROR]: (state) => {
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false
      };
    },
    [ACTIONS.LOGOUT]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.LOGOUT_SUCCESS]: (state) => {
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false
      };
    },
    [ACTIONS.LOGOUT_ERROR]: (state) => {
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false
      };
    },
    [ACTIONS.GET_PROFESSIONALS]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_PROFESSIONALS_SUCCESS]: (state, { payload: professionals }) => {
      return {
        ...state,
        isLoading: false,
        professionals
      };
    },
    [ACTIONS.GET_PROFESSIONALS_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.GET_CLIENTS]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_CLIENTS_SUCCESS]: (state, { payload: clients }) => {
      return {
        ...state,
        isLoading: false,
        clients
      };
    },
    [ACTIONS.GET_CLIENTS_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.GET_USER]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_USER_SUCCESS]: (state, { payload: selectedUser }) => {
      return {
        ...state,
        isLoading: false,
        selectedUser
      };
    },
    [ACTIONS.GET_USER_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.UPDATE_USER]: (state, useLoading = true) => {
      return {
        ...state,
        isLoading: useLoading
      };
    },
    [ACTIONS.UPDATE_USER_SUCCESS]: (state, { payload: selectedUser }) => {
      if (selectedUser.realm === 'professional') {
        const sU = {
          ...selectedUser,
          socialSecurity: state.selectedUser.socialSecurity,
          profession: state.selectedUser.profession
        };

        return {
          ...state,
          isLoading: false,
          selectedUser: sU
        };
      }

      return {
        ...state,
        isLoading: false,
        selectedUser
      };
    },
    [ACTIONS.UPDATE_USER_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.SEND_NOTIFICATION]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.SEND_NOTIFICATION_SUCCESS]: (state) => {
      return {
        ...state,
        isLoading: false
      };
    },
    [ACTIONS.SEND_NOTIFICATION_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.GET_CLIENT_DEBTS]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_CLIENT_DEBTS_SUCCESS]: (state, { payload: clientDebts }) => {
      return {
        ...state,
        isLoading: false,
        clientDebts
      };
    },
    [ACTIONS.GET_CLIENT_DEBTS_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
  },
  initialState
);
