import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import { HiringActions } from '../../modules/actions';
import { HiringPaymentTable, Loader } from '../../components';

const Payments = () => {
  const dispatch = useDispatch();
  const { isLoading, hiringDebts = [] } = useSelector(state => state.hiring);

  useEffect(() => {
    dispatch(HiringActions.GET_HIRING_DEBTS({
      filter: {
        include: ['user'],
        order: 'createdAt'
      }
    }));
  }, [dispatch]);

  const panes = [
    {
      menuItem: 'NO PAGADAS',
      render: () => (
        <HiringPaymentTable
          items={hiringDebts.filter(hiringDebt => hiringDebt.status === 'pending' || hiringDebt.status === 'unpaid')}
          type='paid'
        />
      ),
    }, {
      menuItem: 'PAGADAS',
      render: () => (
        <HiringPaymentTable
          items={hiringDebts.filter(hiringDebt => hiringDebt.status === 'paid')}
          type='notPaid'
        />
      ),
    }
  ]

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {/* <Input icon='search' placeholder='Busca por el nombre o cédula' iconPosition='left' transparent fluid/> */}

      <Tab menu={{ pointing: true }} panes={panes} />
    </div>
  );
};

export default Payments;
