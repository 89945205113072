import { handleActions } from 'redux-actions';
import { HiringActions as ACTIONS } from '../actions';

const initialState = {
  isLoading: false,
  apiError: '',
  hirings: [],
  selectedHiring: {},
  hiringDebts: [],
  hiringDebt: {},
  hiringConfirmations: [],
  hiringValues: []
};

export const hiringReducer = handleActions(
  {
    [ACTIONS.GET_HIRINGS]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_HIRINGS_SUCCESS]: (state, { payload: hirings } ) => {
      return {
        ...state,
        isLoading: false,
        hirings
      };
    },
    [ACTIONS.GET_HIRINGS_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.GET_HIRING]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_HIRING_SUCCESS]: (state, { payload: selectedHiring } ) => {
      return {
        ...state,
        isLoading: false,
        selectedHiring
      };
    },
    [ACTIONS.GET_HIRING_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.PATCH_HIRING]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.PATCH_HIRING_SUCCESS]: (state) => {
      return {
        ...state,
        isLoading: false
      };
    },
    [ACTIONS.PATCH_HIRING_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.PATCH_HIRINGS]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.PATCH_HIRINGS_SUCCESS]: (state) => {
      return {
        ...state,
        isLoading: false
      };
    },
    [ACTIONS.PATCH_HIRINGS_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.POST_HIRING_CLOSE]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.POST_HIRING_CLOSE_SUCCESS]: (state) => {
      return {
        ...state,
        isLoading: false,
        selectedHiring: {}
      };
    },
    [ACTIONS.POST_HIRING_CLOSE_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.POST_HIRING_CANCEL]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.POST_HIRING_CANCEL_SUCCESS]: (state) => {
      return {
        ...state,
        isLoading: false,
        selectedHiring: {}
      };
    },
    [ACTIONS.POST_HIRING_CANCEL_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.POST_HIRING_ENABLE]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.POST_HIRING_ENABLE_SUCCESS]: (state) => {
      return {
        ...state,
        isLoading: false
      };
    },
    [ACTIONS.POST_HIRING_ENABLE_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.POST_HIRING_RESEND]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.POST_HIRING_RESEND_SUCCESS]: (state) => {
      return {
        ...state,
        isLoading: false
      };
    },
    [ACTIONS.POST_HIRING_RESEND_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.GET_HIRING_DEBTS]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_HIRING_DEBTS_SUCCESS]: (state, { payload: hiringDebts } ) => {
      return {
        ...state,
        isLoading: false,
        hiringDebts
      };
    },
    [ACTIONS.GET_HIRING_DEBTS_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.GET_HIRING_DEBT]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_HIRING_DEBT_SUCCESS]: (state, { payload: hiringDebt } ) => {
      return {
        ...state,
        isLoading: false,
        hiringDebt
      };
    },
    [ACTIONS.GET_HIRING_DEBT_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.POST_PAY]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.POST_PAY_SUCCESS]: (state ) => {
      return {
        ...state,
        isLoading: false
      };
    },
    [ACTIONS.POST_PAY_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.GET_HIRING_CONFIRMATIONS]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_HIRING_CONFIRMATIONS_SUCCESS]: (state, { payload: hiringConfirmations } ) => {
      return {
        ...state,
        isLoading: false,
        hiringConfirmations
      };
    },
    [ACTIONS.GET_HIRING_CONFIRMATIONS_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
    [ACTIONS.GET_HIRING_VALUES]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_HIRING_VALUES_SUCCESS]: (state, { payload: hiringValues } ) => {
      return {
        ...state,
        isLoading: false,
        hiringValues
      };
    },
    [ACTIONS.GET_HIRING_VALUES_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        isLoading: false,
        apiError
      };
    },
  },
  initialState
);
