import { createAction } from 'redux-actions';

const TYPES = {
  GET_FAQ: 'GET_FAQ',
  GET_FAQ_SUCCESS: 'GET_FAQ_SUCCESS',
  GET_FAQ_ERROR: 'GET_FAQ_ERROR',

  DELETE_FAQ: 'DELETE_FAQ',

  POST_FAQ: 'POST_FAQ',
  POST_FAQ_SUCCESS: 'POST_FAQ_SUCCESS',
  POST_FAQ_ERROR: 'POST_FAQ_ERROR',

  UPDATE_FAQ: 'UPDATE_FAQ',
  UPDATE_FAQ_SUCCESS: 'UPDATE_FAQ_SUCCESS',
  UPDATE_FAQ_ERROR: 'UPDATE_FAQ_ERROR'
};

const ACTIONS = {
  GET_FAQ: createAction(TYPES.GET_FAQ),
  GET_FAQ_SUCCESS: createAction(TYPES.GET_FAQ_SUCCESS),
  GET_FAQ_ERROR: createAction(TYPES.GET_FAQ_ERROR),

  DELETE_FAQ: createAction(TYPES.DELETE_FAQ),

  POST_FAQ: createAction(TYPES.POST_FAQ),
  POST_FAQ_SUCCESS: createAction(TYPES.POST_FAQ_SUCCESS),
  POST_FAQ_ERROR: createAction(TYPES.POST_FAQ_ERROR),

  UPDATE_FAQ: createAction(TYPES.UPDATE_FAQ),
  UPDATE_FAQ_SUCCESS: createAction(TYPES.UPDATE_FAQ_SUCCESS),
  UPDATE_FAQ_ERROR: createAction(TYPES.UPDATE_FAQ_ERROR)
};

export default ACTIONS;
