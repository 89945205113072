import { get, isEmpty } from 'lodash';
import moment from 'moment';

import { API_URL, releaseChannel } from './constants';

const emailValidator = require('email-validator');
const xlsx = require('json-as-xlsx');

export const showAvatar = (user) => {
  const storage = localStorage.getItem('login');
  const token = get(JSON.parse(storage), 'id', null);

  const image = user && user.avatar ? user.avatar : 'default.jpeg';
  return `${API_URL}Containers/holper-profile-images-${releaseChannel}/download/${image}?access_token=${token}`;
};

export const showImage = (image, container = 'holper-profile-images') => {
  const storage = localStorage.getItem('login');
  const token = get(JSON.parse(storage), 'id', null);

  return `${API_URL}Containers/${container}-${releaseChannel}/download/${image}?access_token=${token}`;
};

export const showVideo = (hiring, container = 'holper-videos') => {
  return hiring ? `https://${container}-${releaseChannel}.s3.us-east-2.amazonaws.com/${hiring.video}` : ''
};

export const validEmail = (email) => {
  return emailValidator.validate(email);
};

export const sendWS = (channel, type) => {
  // socket.publish({
  //   channel,
  //   message: { type }
  // });
};

export const numberWithCommas = (x, delimiter = '.') => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
  }
  return 0;
};

export const getHiringStateName = (state) => {
  switch (state) {
    case 'pending':
      return 'Pendiente';
    case 'onTheWay':
      return 'En camino';
    case 'arrivalConfirmed':
      return 'Llegada confirmada';
    case 'inProgress':
      return 'Trabajo iniciado';
    case 'payment':
      return 'Procesando pago';
    case 'canceled':
      return 'Cancelado';
    case 'done':
      return 'Completado';
    default:
      return 'N/A';
  }
};

export const formatAddress = (address) => {
  if (isEmpty(address)) return 'No Definida';

  return `${address.address}, ${address.sector} - ${address.municipality}, ${address.country}, ${address.indications}`
};

export const calcHiringPrices = (hiring) => {

  const { hiringExtraTasks, commission, cashDiscount } = hiring;

  const extraTaskValue = hiringExtraTasks.length ? hiringExtraTasks.map(extra => {
    return extra.state === 'approved' ? Number(extra.value) : 0;
  }).reduce((accumulator, currentValue) => accumulator + currentValue, 0) : 0;

  const value = Number(hiring.value);
  const holperValue = value * (Number(commission));
  const discountPercentage = (Number(cashDiscount) * 100) * 100 / (Number(commission) * 100);
  const discountValue = (holperValue * discountPercentage / 100);
  const totalValue = hiring.cardId ? Number(value) + Number(holperValue) : Number(value) + Number(holperValue) - Number(discountValue);

  return {
    value: numberWithCommas(value),
    commission,
    total: numberWithCommas(totalValue + extraTaskValue)
  };
};

export const exportHiringToXLSX = (items, from, to) => {
  const formattedFrom = moment(from, 'DD-MM-YYYY', true).utc().format();
  const formattedTo = moment(to, 'DD-MM-YYYY', true).utc().format();
  const columns = [
    { label: 'Número de transacción', value: hiring => hiring.transactionId || 'N/A' },
    { label: 'Nombres', value: ({ professional }) => professional.name },
    { label: 'Cédula', value: ({ professional }) => professional.ci },
    { label: 'Cuenta bancaria', value: ({ professional }) => professional.accountNumber },
    { label: 'Tipo de cuenta', value: ({ professional }) => professional.bankAccountType.name },
    { label: 'Banco', value: ({ professional }) => professional.bank.name },
    { label: 'RUT', value: ({ professional }) => showImage(professional.basicService, 'holper-docs') },
    { label: 'Valor a recibir', value: hiring => calcHiringPrices(hiring).value },
    { label: '30% de holper', value: hiring => calcHiringPrices(hiring).commission },
    { label: 'Suma de ambos', value: hiring => calcHiringPrices(hiring).total },
    { label: 'Fecha de pago', value: hiring => moment(hiring.doneDate).format('DD-MM-YYYY hh:mm a').toString() },
    { label: 'Estado del pago', value: () => 'Aprobado' }
  ];
  const settings = {
    sheetName: `${moment(formattedFrom).format('DD-MM-YYYY')} - ${moment(formattedTo).format('DD-MM-YYYY')}`, // The name of the sheet
    fileName: moment().format('DD-MM-YYYY - hh:mm a').toString(), // The name of the spreadsheet
    extraLength: 5, // A bigger number means that columns should be wider
    writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
  };
  const download = true; // If true will download the xlsx file, otherwise will return a buffer

  const hirings = items.filter(hiring => (
    moment(hiring.date).isBetween(moment(formattedFrom), moment(formattedTo))
  ));

  xlsx(columns, hirings, settings, download); // Will download the excel file
};

export const calculateDebt = (hiring) => {
  const { cashDiscount, commission, value, hiringExtraTasks, coupon } = hiring;

  const extraTaskValue = hiringExtraTasks.length ? hiringExtraTasks.map(extra => {
    return extra.state === 'approved' ? Number(extra.value) : 0;
  }).reduce((accumulator, currentValue) => accumulator + currentValue, 0) : 0;

  return Math.round(((Number(value) + extraTaskValue) - Number(coupon ? coupon.value : 0)) * (Number(commission) - Number(cashDiscount)));
}

export const currencyFormat = (value) => {
  if (!value) return '$0.00';

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return formatter.format(value);
}
