import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';
import ActiveTable from './ActiveTable';
import DisabledTable from './DisabledTable';
import InactiveTable from './InactiveTable';
import VerifyTable from './VerifyTable';

const HolpersTables = ({ items, type, cities }) => {
  const renderTable = () => {
    switch (type) {
      case 'active':
        return <ActiveTable items={items} cities={cities} />;
      case 'disabled':
        return <DisabledTable items={items} cities={cities} />;
      case 'inactive':
        return <InactiveTable items={items} cities={cities} />;
      case 'verify':
        return <VerifyTable items={items} cities={cities} type={type} />;
      case 'rejected':
        return <VerifyTable items={items} cities={cities} type={type} />;
      default:
        break;
    }
  };

  return (
    <Tab.Pane attached={false}>
      {renderTable()}
    </Tab.Pane>
  );
}

HolpersTables.propTypes = {
  type: PropTypes.oneOf(['active', 'disabled', 'inactive', 'verify']),
  items: PropTypes.array,
  loading: PropTypes.bool
};

export default HolpersTables;
