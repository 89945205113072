import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { userReducer } from './user';
import { hiringReducer } from './hiring';
import { helpReducer } from './help';
import { commonReducer } from './common';
import { couponReducer } from './coupon';

export default (history) => combineReducers( {
  user: userReducer,
  hiring: hiringReducer,
  help: helpReducer,
  common: commonReducer,
  coupon: couponReducer,
  router: connectRouter(history)
} );
