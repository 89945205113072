import { takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { HelpActions } from '../actions';

export function* getFaq( api ) {
  try {
    const { ok, status, data } = yield call(api.getFaq);

    if(ok && status === 200) {
      yield put( HelpActions.GET_FAQ_SUCCESS(data));
    } else {
      throw new Error( 'Error desde el servidor' );
    }
  } catch(e) {
    debugger
    yield put( HelpActions.GET_FAQ_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* deleteFaq( api, { payload: id } ) {
  try {
    const { ok, status } = yield call(api.deleteFaq, id);

    if(ok && status === 200) {
      yield call( getFaq, api );
    } else {
      throw new Error( 'Error desde el servidor' );
    }
  } catch(e) {
    yield put( HelpActions.GET_FAQ_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* postFaq( api, { payload } ) {
  try {
    const { ok, status, data } = yield call(api.postFaq, payload);

    if(ok && status === 200) {
      yield put( HelpActions.POST_FAQ_SUCCESS(data));
    } else {
      throw new Error( 'Error desde el servidor' );
    }
  } catch(e) {
    yield put( HelpActions.POST_FAQ_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* updateFaq( api, { payload } ) {
  debugger;
  try {
    const { ok, status } = yield call(api.updateFaq, payload);

    if(ok && status === 200) {
      yield call( getFaq, api );
      yield put( HelpActions.UPDATE_FAQ_SUCCESS());
    } else {
      throw new Error( 'Error desde el servidor' );
    }
  } catch(e) {
    yield put( HelpActions.UPDATE_FAQ_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* watchHelp( api ) {
  yield takeLatest( 'GET_FAQ', getFaq, api );
  yield takeLatest( 'DELETE_FAQ', deleteFaq, api );
  yield takeLatest( 'POST_FAQ', postFaq, api );
  yield takeLatest( 'UPDATE_FAQ', updateFaq, api );
}
