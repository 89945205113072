import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions, CommonActions } from '../../modules/actions';
import { Header, Tab } from 'semantic-ui-react';
import { ClientTable, Loader } from '../../components';

const Clients = () => {
  const dispatch = useDispatch();
  const { isLoading, clients } = useSelector(state => state.user);
  const { cities } = useSelector(state => state.common);

  useEffect(() => {
    dispatch(UserActions.GET_CLIENTS());
    dispatch(CommonActions.GET_CITIES());
  }, [dispatch]);

  const panes = [
    {
      menuItem: 'ACTIVOS',
      render: () => (
        <ClientTable
          items={clients}
          type='actives'
          cities={cities}
        />
      )
    }
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Header as='h1' textAlign='center'>CLIENTES</Header>

      {/* <Tab menu={{ pointing: true }} panes={panes} /> */}

      <ClientTable
        items={clients}
        type='actives'
        cities={cities}
      />
    </div>
  );
}

export default Clients;