import { handleActions } from 'redux-actions';
import { CouponActions as ACTIONS } from '../actions';

const initialState = {
  coupons: [],
  isLoading: true,
  apiError: ''
};

export const couponReducer = handleActions({
    [ACTIONS.GET_COUPONS]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_COUPONS_SUCCESS]: (state, { payload: coupons }) => {
      return {
        ...state,
        coupons,
        isLoading: false
      };
    },
    [ACTIONS.GET_COUPONS_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        apiError,
        isLoading: false
      };
    },
    [ACTIONS.POST_COUPONS]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.POST_COUPONS_SUCCESS]: (state) => {
      return {
        ...state,
        isLoading: false
      };
    },
    [ACTIONS.POST_COUPONS_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        apiError,
        isLoading: false
      };
    },
    [ACTIONS.POST_HOLPER_COUPONS]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.POST_HOLPER_COUPONS_SUCCESS]: (state) => {
      return {
        ...state,
        isLoading: false
      };
    },
    [ACTIONS.POST_HOLPER_COUPONS_SUCCESS]: (state, { error: apiError }) => {
      return {
        ...state,
        apiError,
        isLoading: false
      };
    }
  },
  initialState
);
