import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { Input } from "semantic-ui-react";

const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
  <Input value={value} onClick={onClick} ref={ref} />
));

const DatePickerInput = ({ selected, onChange }) => {
  return (
    <DatePicker
      selected={selected}
      onChange={onChange}
      locale="es"
      dateFormat="dd/MM/yyyy"
      minDate={new Date()}
      customInput={<CustomDateInput />}
    />
  )
}

export default DatePickerInput;