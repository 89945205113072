import { createAction } from 'redux-actions';

const TYPES = {
  GET_HIRINGS: 'GET_HIRINGS',
  GET_HIRINGS_SUCCESS: 'GET_HIRINGS_SUCCESS',
  GET_HIRINGS_ERROR: 'GET_HIRINGS_ERROR',

  GET_HIRING: 'GET_HIRING',
  GET_HIRING_SUCCESS: 'GET_HIRING_SUCCESS',
  GET_HIRING_ERROR: 'GET_HIRING_ERROR',

  GET_HIRING_DELETED_INCLUDED: 'GET_HIRING_DELETED_INCLUDED',
  GET_HIRING_DELETED_INCLUDED_SUCCESS: 'GET_HIRING_DELETED_INCLUDED_SUCCESS',
  GET_HIRING_DELETED_INCLUDED_ERROR: 'GET_HIRING_DELETED_INCLUDED_ERROR',

  PATCH_HIRING: 'PATCH_HIRING',
  PATCH_HIRING_SUCCESS: 'PATCH_HIRING_SUCCESS',
  PATCH_HIRING_ERROR: 'PATCH_HIRING_ERROR',

  PATCH_HIRINGS: 'PATCH_HIRINGS',
  PATCH_HIRINGS_SUCCESS: 'PATCH_HIRINGS_SUCCESS',
  PATCH_HIRINGS_ERROR: 'PATCH_HIRINGS_ERROR',

  POST_HIRING_CLOSE: 'POST_HIRING_CLOSE',
  POST_HIRING_CLOSE_SUCCESS: 'POST_HIRING_CLOSE_SUCCESS',
  POST_HIRING_CLOSE_ERROR: 'POST_HIRING_CLOSE_ERROR',

  POST_HIRING_CANCEL: 'POST_HIRING_CANCEL',
  POST_HIRING_CANCEL_SUCCESS: 'POST_HIRING_CANCEL_SUCCESS',
  POST_HIRING_CANCEL_ERROR: 'POST_HIRING_CANCEL_ERROR',

  POST_HIRING_ENABLE: 'POST_HIRING_ENABLE',
  POST_HIRING_ENABLE_SUCCESS: 'POST_HIRING_ENABLE_SUCCESS',
  POST_HIRING_ENABLE_ERROR: 'POST_HIRING_ENABLE_ERROR',

  POST_HIRING_RESEND: 'POST_HIRING_RESEND',
  POST_HIRING_RESEND_SUCCESS: 'POST_HIRING_RESEND_SUCCESS',
  POST_HIRING_RESEND_ERROR: 'POST_HIRING_RESEND_ERROR',

  GET_HIRING_DEBTS: 'GET_HIRING_DEBTS',
  GET_HIRING_DEBTS_SUCCESS: 'GET_HIRING_DEBTS_SUCCESS',
  GET_HIRING_DEBTS_ERROR: 'GET_HIRING_DEBTS_ERROR',

  GET_HIRING_DEBT: 'GET_HIRING_DEBT',
  GET_HIRING_DEBT_SUCCESS: 'GET_HIRING_DEBT_SUCCESS',
  GET_HIRING_DEBT_ERROR: 'GET_HIRING_DEBT_ERROR',

  GET_HIRING_CONFIRMATIONS: 'GET_HIRING_CONFIRMATIONS',
  GET_HIRING_CONFIRMATIONS_SUCCESS: 'GET_HIRING_CONFIRMATIONS_SUCCESS',
  GET_HIRING_CONFIRMATIONS_ERROR: 'GET_HIRING_CONFIRMATIONS_ERROR',

  UPDATE_HIRING_DEBT: 'UPDATE_HIRING_DEBT',
  UPDATE_HIRING_DEBT_SUCCESS: 'UPDATE_HIRING_DEBT_SUCCESS',
  UPDATE_HIRING_DEBT_ERROR: 'UPDATE_HIRING_DEBT_ERROR',

  POST_PAY: 'POST_PAY',
  POST_PAY_SUCCESS: 'POST_PAY_SUCCESS',
  POST_PAY_ERROR: 'POST_PAY_ERROR',

  GET_HIRING_VALUES: 'GET_HIRING_VALUES',
  GET_HIRING_VALUES_SUCCESS: 'GET_HIRING_VALUES_SUCCESS',
  GET_HIRING_VALUES_ERROR: 'GET_HIRING_VALUES_ERROR',

  POST_HIRING_VALUE: 'POST_HIRING_VALUE',
  POST_HIRING_VALUE_SUCCESS: 'POST_HIRING_VALUE_SUCCESS',
  POST_HIRING_VALUE_ERROR: 'POST_HIRING_VALUE_ERROR',

  PATCH_HIRING_VALUE: 'PATCH_HIRING_VALUE',
  PATCH_HIRING_VALUE_SUCCESS: 'PATCH_HIRING_VALUE_SUCCESS',
  PATCH_HIRING_VALUE_ERROR: 'PATCH_HIRING_VALUE_ERROR',

  DELETE_HIRING_VALUE: 'DELETE_HIRING_VALUE',
  DELETE_HIRING_VALUE_SUCCESS: 'DELETE_HIRING_VALUE_SUCCESS',
  DELETE_HIRING_VALUE_ERROR: 'DELETE_HIRING_VALUE_ERROR',
};

const ACTIONS = {
  GET_HIRINGS: createAction(TYPES.GET_HIRINGS),
  GET_HIRINGS_SUCCESS: createAction(TYPES.GET_HIRINGS_SUCCESS),
  GET_HIRINGS_ERROR: createAction(TYPES.GET_HIRINGS_ERROR),

  GET_HIRING: createAction(TYPES.GET_HIRING),
  GET_HIRING_SUCCESS: createAction(TYPES.GET_HIRING_SUCCESS),
  GET_HIRING_ERROR: createAction(TYPES.GET_HIRING_ERROR),

  GET_HIRING_DELETED_INCLUDED: createAction(TYPES.GET_HIRING_DELETED_INCLUDED),
  GET_HIRING_DELETED_INCLUDED_SUCCESS: createAction(TYPES.GET_HIRING_DELETED_INCLUDED_SUCCESS),
  GET_HIRING_DELETED_INCLUDED_ERROR: createAction(TYPES.GET_HIRING_DELETED_INCLUDED_ERROR),

  PATCH_HIRING: createAction(TYPES.PATCH_HIRING),
  PATCH_HIRING_SUCCESS: createAction(TYPES.PATCH_HIRING_SUCCESS),
  PATCH_HIRING_ERROR: createAction(TYPES.PATCH_HIRING_ERROR),

  PATCH_HIRINGS: createAction(TYPES.PATCH_HIRINGS),
  PATCH_HIRINGS_SUCCESS: createAction(TYPES.PATCH_HIRINGS_SUCCESS),
  PATCH_HIRINGS_ERROR: createAction(TYPES.PATCH_HIRINGS_ERROR),

  POST_HIRING_CLOSE: createAction(TYPES.POST_HIRING_CLOSE),
  POST_HIRING_CLOSE_SUCCESS: createAction(TYPES.POST_HIRING_CLOSE_SUCCESS),
  POST_HIRING_CLOSE_ERROR: createAction(TYPES.POST_HIRING_CLOSE_ERROR),

  POST_HIRING_CANCEL: createAction(TYPES.POST_HIRING_CANCEL),
  POST_HIRING_CANCEL_SUCCESS: createAction(TYPES.POST_HIRING_CANCEL_SUCCESS),
  POST_HIRING_CANCEL_ERROR: createAction(TYPES.POST_HIRING_CANCEL_ERROR),

  POST_HIRING_ENABLE: createAction(TYPES.POST_HIRING_ENABLE),
  POST_HIRING_ENABLE_SUCCESS: createAction(TYPES.POST_HIRING_ENABLE_SUCCESS),
  POST_HIRING_ENABLE_ERROR: createAction(TYPES.POST_HIRING_ENABLE_ERROR),

  POST_HIRING_RESEND: createAction(TYPES.POST_HIRING_RESEND),
  POST_HIRING_RESEND_SUCCESS: createAction(TYPES.POST_HIRING_RESEND_SUCCESS),
  POST_HIRING_RESEND_ERROR: createAction(TYPES.POST_HIRING_RESEND_ERROR),

  GET_HIRING_DEBTS: createAction(TYPES.GET_HIRING_DEBTS),
  GET_HIRING_DEBTS_SUCCESS: createAction(TYPES.GET_HIRING_DEBTS_SUCCESS),
  GET_HIRING_DEBTS_ERROR: createAction(TYPES.GET_HIRING_DEBTS_ERROR),

  GET_HIRING_CONFIRMATIONS: createAction(TYPES.GET_HIRING_CONFIRMATIONS),
  GET_HIRING_CONFIRMATIONS_SUCCESS: createAction(TYPES.GET_HIRING_CONFIRMATIONS_SUCCESS),
  GET_HIRING_CONFIRMATIONS_ERROR: createAction(TYPES.GET_HIRING_CONFIRMATIONS_ERROR),

  GET_HIRING_DEBT: createAction(TYPES.GET_HIRING_DEBT),
  GET_HIRING_DEBT_SUCCESS: createAction(TYPES.GET_HIRING_DEBT_SUCCESS),
  GET_HIRING_DEBT_ERROR: createAction(TYPES.GET_HIRING_DEBT_ERROR),

  UPDATE_HIRING_DEBT: createAction(TYPES.UPDATE_HIRING_DEBT),
  UPDATE_HIRING_DEBT_SUCCESS: createAction(TYPES.UPDATE_HIRING_DEBT_SUCCESS),
  UPDATE_HIRING_DEBT_ERROR: createAction(TYPES.UPDATE_HIRING_DEBT_ERROR),

  POST_PAY: createAction(TYPES.POST_PAY),
  POST_PAY_SUCCESS: createAction(TYPES.POST_PAY_SUCCESS),
  POST_PAY_ERROR: createAction(TYPES.POST_PAY_ERROR),

  GET_HIRING_VALUES: createAction(TYPES.GET_HIRING_VALUES),
  GET_HIRING_VALUES_SUCCESS: createAction(TYPES.GET_HIRING_VALUES_SUCCESS),
  GET_HIRING_VALUES_ERROR: createAction(TYPES.GET_HIRING_VALUES_ERROR),

  POST_HIRING_VALUE: createAction(TYPES.POST_HIRING_VALUE),
  POST_HIRING_VALUE_SUCCESS: createAction(TYPES.POST_HIRING_VALUE_SUCCESS),
  POST_HIRING_VALUE_ERROR: createAction(TYPES.POST_HIRING_VALUE_ERROR),

  PATCH_HIRING_VALUE: createAction(TYPES.PATCH_HIRING_VALUE),
  PATCH_HIRING_VALUE_SUCCESS: createAction(TYPES.PATCH_HIRING_VALUE_SUCCESS),
  PATCH_HIRING_VALUE_ERROR: createAction(TYPES.PATCH_HIRING_VALUE_ERROR),

  DELETE_HIRING_VALUE: createAction(TYPES.DELETE_HIRING_VALUE),
  DELETE_HIRING_VALUE_SUCCESS: createAction(TYPES.DELETE_HIRING_VALUE_SUCCESS),
  DELETE_HIRING_VALUE_ERROR: createAction(TYPES.DELETE_HIRING_VALUE_ERROR),
};

export default ACTIONS;
