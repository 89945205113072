import React, { useEffect } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Icon, Header, Tab, Table } from 'semantic-ui-react';
import { CommonActions, UserActions } from '../../modules/actions';
import { NavigationTitle, Loader, HolperDetailsHeader, HolperDocument } from '../../components';
import { formatAddress } from '../../common/utils';

const HolperDetailsVerify = ({ match }) => {
  const dispatch = useDispatch();
  const { isLoading, selectedUser } = useSelector(state => state.user);

  useEffect(() => {
    const userId = get(match, 'params.id', null);
    const filter = {
      where: { realm: 'professional' },
      include: ['address', 'addresses', 'profession', 'socialSecurity', 'education', 'bank', 'bankAccountType']
    };
    dispatch(UserActions.GET_USER({ userId, filter }));
  }, [dispatch, match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps


  const updateUser = async (prop, value) => {
    const user = {
      ...selectedUser,
      [prop]: value
    };

    const { basicServiceStatus, documentsStatus, bankAccountStatus, socialSecurityStatus, vaccinationCertificationStatus } = user;
    const emailTemplate = value === 'rejected' ? 'rejectedDocument' : 'approvedDocument';

    const documentDictionary = {
      basicServiceStatus: 'EXPERIENCIA',
      documentsStatus: 'DOCUMENTO DE IDENTIDAD',
      bankAccountStatus: 'CUENTA BANCARIA',
      socialSecurityStatus: 'SEGURIDAD SOCIAL',
      vaccinationCertificationStatus: 'CERTIFICADO DE VACUNACIÓN'
    }

    const templateData = {
      emails: [selectedUser.email],
      name: emailTemplate,
      data: {
        name: selectedUser.name,
        document: documentDictionary[prop]
      }
    };

    await dispatch(UserActions.UPDATE_USER(user));
    await dispatch(CommonActions.SEND_EMAIL_BY_TEMPLATE(templateData));

    if (basicServiceStatus === 'approved' && documentsStatus === 'approved' && bankAccountStatus === 'approved' && socialSecurityStatus === 'approved' && vaccinationCertificationStatus === 'approved') {
      await dispatch(CommonActions.SEND_PUSH_NOTIFICATION(
        {
          userId: selectedUser.id,
          data: { body: '¡Felicidades! :) Tu cuenta se encuentra activa, ya puedes avanzar con el registro.', data: {} }
        }
      ))
    }
  };

  if (isLoading || !selectedUser || !selectedUser.hasOwnProperty('id')) {
    return <Loader />;
  }

  const panes = [
    {
      menuItem: 'Completa tu experiencia',
      render: () => (
        <>
          <Table basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Años de experiencia</Table.HeaderCell>
                <Table.HeaderCell>Nivel de educación</Table.HeaderCell>
                <Table.HeaderCell>Dirección de domicilo</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{selectedUser.yearsOfExperience || 'N/A'}</Table.Cell>
                <Table.Cell>{selectedUser.education ? selectedUser.education.name['es'] : 'N/A'}</Table.Cell>
                <Table.Cell>{formatAddress(selectedUser.address.find(a => a.default)) || 'N/A'}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <br />

          <Tab.Pane attached={false} style={{ display: 'flex', flexDirection: 'column' }}>
            <HolperDocument
              documents={[
                { key: 'laborRecommendation1', value: selectedUser.laborRecommendation1 },
                { key: 'laborRecommendation2', value: selectedUser.laborRecommendation2 },
                { key: 'basicService', value: selectedUser.basicService }
              ]}
              status={selectedUser.basicServiceStatus}
              onResult={(result) => {
                updateUser('basicServiceStatus', result);
              }}
              hasOptional
              userId={selectedUser.id}
              container="holper-docs"
            />
          </Tab.Pane>
        </>
      ),
    },
    {
      menuItem: 'Documentos de identidad',
      render: () => (
        <Tab.Pane attached={false}>
          <HolperDocument
            documents={[
              { key: 'ciFront', value: selectedUser.ciFront },
              { key: 'ciBack', value: selectedUser.ciBack }
            ]}
            status={selectedUser.documentsStatus}
            onResult={(result) => updateUser('documentsStatus', result)}
            userId={selectedUser.id}
            container="holper-docs"
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Cuenta bancaria',
      render: () => (
        <Tab.Pane attached={false}>
          <>
            <Table basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nombre del banco</Table.HeaderCell>
                  <Table.HeaderCell>Tipo de cuenta</Table.HeaderCell>
                  <Table.HeaderCell>Número de cuenta</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>{selectedUser.bank ? selectedUser.bank.name : 'N/A'}</Table.Cell>
                  <Table.Cell>{selectedUser.bankAccountType ? selectedUser.bankAccountType.name : 'N/A'}</Table.Cell>
                  <Table.Cell>{selectedUser.accountNumber || 'N/A'}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <br />
            <HolperDocument
              documents={[
                { key: 'bankCertification', value: selectedUser.bankCertification }
              ]}
              status={selectedUser.bankAccountStatus}
              onResult={(result) => updateUser('bankAccountStatus', result)}
              userId={selectedUser.id}
              container="holper-docs"
            />
          </>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Seguridad Social',
      render: () => (
        <Tab.Pane attached={false}>
          <>
            <Table basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nombre de aseguradora</Table.HeaderCell>
                  <Table.HeaderCell>Número de documento</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>{selectedUser.socialSecurity ? selectedUser.socialSecurity.name : 'N/A'}</Table.Cell>
                  <Table.Cell>{selectedUser.socialSecurityNumber || 'N/A'}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

            <br />
            <HolperDocument
              documents={[]}
              status={selectedUser.socialSecurityStatus}
              onResult={(result) => updateUser('socialSecurityStatus', result)}
              userId={selectedUser.id}
              container="holper-docs"
            />
          </>
        </Tab.Pane>
      ),
    }
  ]

  if (process.env.REACT_APP_ENV === "prod") {
    panes.push(
      {
        menuItem: 'Certificado de Vacunación',
        render: () => (
          <Tab.Pane attached={false}>
            <>
              <HolperDocument
                documents={[
                  { key: 'vaccinationCertification', value: selectedUser.vaccinationCertification }
                ]}
                status={selectedUser.vaccinationCertificationStatus}
                onResult={(result) => updateUser('vaccinationCertificationStatus', result)}
                userId={selectedUser.id}
                container="holper-docs"
              />
            </>
          </Tab.Pane>
        ),
      }
    );
  }

  return (
    <div>
      <NavigationTitle label='Holpers por verificar' />

      <HolperDetailsHeader holper={selectedUser} />

      <br />
      <Divider horizontal>
        <Header as='h4'>
          <Icon name='file alternate outline' size='small' />
          DOCUMENTOS
        </Header>
      </Divider>
      <br />

      <Tab menu={{ pointing: true }} panes={panes} />
    </div>
  );
}

export default HolperDetailsVerify;
