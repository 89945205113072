import React, { useState, useEffect } from 'react';
import { Icon, Menu, Table, Button, Label, Grid, Checkbox, Select } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import ExcelHolper from '../ExcelHolper';

const VerifyTable = ({ items, type, cities }) => {
  const [paginationCount, setPaginationCount] = useState(1);
  const [paginationIndex, setPaginationIndex] = useState(1);
  const [holpers, setHolpers] = useState([]);
  const [verified, setVerified] = useState(true);
  const [city, setCity] = useState('');

  useEffect(() => {
    setPaginationCount(Math.ceil(items.length / 10));

    if (verified) {
      onChangeVerified();
    } else {
      setHolpers(items);
    }
  }, [items, verified]);

  useEffect(() => {
    if (verified) {
      const verifiedHolpers = items.filter(professional => {
        const info = professional.yearsOfExperience && professional.educationId &&
          professional.laborRecommendation1 && professional.laborRecommendation2 &&
          professional.ciFront && professional.ciBack && professional.bankCertification && professional.bankId && professional.bankAccountTypeId;

        if (info && (city ? professional.city === city : true)) {
          return professional;
        }
      });

      setHolpers(verifiedHolpers);
    } else {
      setHolpers(items.filter(p => (city ? p.city === city : true)));
    }
  }, [city, items, verified])

  const renderPaginationIndexes = () => {
    const indexes = [];

    for (let i = 0; i < paginationCount; i++) {
      indexes.push(<Menu.Item disabled={paginationIndex === i + 1} key={`active-${i}`} as='a' onClick={() => setPaginationIndex(i + 1)}>{i + 1}</Menu.Item>);
    }

    return indexes;
  };

  const renderVerifyState = (professional) => {
    const info = professional.yearsOfExperience && professional.educationId &&
      professional.laborRecommendation1 && professional.laborRecommendation2 &&
      professional.ciFront && professional.ciBack && professional.bankCertification && professional.bankId && professional.bankAccountTypeId;

    if (professional.basicServiceStatus === 'rejected' ||
      professional.bankAccountStatus === 'rejected' ||
      professional.documentsStatus === 'rejected' ||
      professional.socialSecurityStatus === 'rejected') {
      return <Label color='red' circular>RECHAZADO</Label>;
    }

    if (info) {
      return <Label color='green' circular>COMPLETO</Label>;
    }

    if (!info) {
      return <Label color='yellow' circular>INCOMPLETO</Label>;
    }
  };

  const onChangeVerified = (e) => {

  }

  return (
    <>
      {/* <Header as='h5' color='grey' textAlign='center'>En este apartado puedes revisar la base de datos de holpers y su estado.</Header> */}

      <Grid>
        <Grid.Row>
          <Grid.Column floated='left' width={3}>
            <ExcelHolper items={holpers} />
          </Grid.Column>

          <Grid.Column floated='left' width={3}>
            <Select
              placeholder='Ciudad'
              options={cities.map(c => ({ key: c.id, value: c.name === 'Todos' ? null : c.name, text: c.name }))}
              defaultValue={city}
              onChange={(e, { value }) => { setCity(value); }}
            />
          </Grid.Column>

          {type === 'verify' &&
            <Grid.Column floated='left' width={4}>
              <Checkbox
                style={{ marginTop: '10px' }}
                label='Mostrar con los documentos completos'
                checked={verified}
                onChange={() => setVerified(!verified)}
              />
            </Grid.Column>}


          <Grid.Column floated='left' width={4} style={{ marginTop: '10px' }}>
            <div>Documentos completos: <strong style={{ fontSize: 20 }}>{holpers.length}</strong></div>
          </Grid.Column>

          {/* <Grid.Column floated='right' width={5}> */}
          {/* <Input value={searchText} onChange={search} icon='search' placeholder='Busca por el nombre o cédula' iconPosition='left' transparent fluid /> */}
          {/* </Grid.Column> */}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>NOMBRE</Table.HeaderCell>
                  <Table.HeaderCell>CÉDULA</Table.HeaderCell>
                  <Table.HeaderCell>CELULAR</Table.HeaderCell>
                  {/* <Table.HeaderCell>CORREO</Table.HeaderCell> */}
                  <Table.HeaderCell>CIUDAD</Table.HeaderCell>
                  <Table.HeaderCell>MUNICIPIO</Table.HeaderCell>
                  <Table.HeaderCell>FECHA DE REGISTRO</Table.HeaderCell>
                  <Table.HeaderCell>ESTADO</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {holpers.map((professional) => (
                  <Table.Row key={professional.id}>
                    <Table.Cell>{professional.name}</Table.Cell>
                    <Table.Cell>{professional.ci}</Table.Cell>
                    <Table.Cell>{professional.phone}</Table.Cell>
                    {/* <Table.Cell>{professional.email}</Table.Cell> */}
                    <Table.Cell>{professional.city}</Table.Cell>
                    <Table.Cell>{professional.address ? professional.address.find(a => a.default)?.municipality : 'N/A'}</Table.Cell>
                    <Table.Cell>{moment(professional.createdAt).format('DD/MM/YYYY')}</Table.Cell>
                    <Table.Cell>{renderVerifyState(professional)}</Table.Cell>
                    <Table.Cell>
                      <Button animated='fade' basic circular as={NavLink} to={`/holpers/${professional.id}/verify`}>
                        <Button.Content visible>Ver más</Button.Content>
                        <Button.Content hidden>
                          <Icon name='eye' />
                        </Button.Content>
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              {/* <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='12'>
                    <Menu floated='right' pagination>
                      <Menu.Item as='a' icon disabled={paginationIndex === 1}>
                        <Icon name='chevron left' />
                      </Menu.Item>
                      {renderPaginationIndexes()}
                      <Menu.Item as='a' icon disabled={paginationIndex === paginationCount || !paginationCount}>
                        <Icon name='chevron right' />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer> */}
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default VerifyTable;