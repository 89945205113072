import { handleActions } from 'redux-actions';
import { CommonActions as ACTIONS } from '../actions';

const initialState = {
  education: [],
  cities: [],
  isLoading: true,
  apiError: '',
  configs: null,
  dashboard: null,
  professions: []
};

export const commonReducer = handleActions({
    [ACTIONS.GET_EDUCATION]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_EDUCATION_SUCCESS]: (state, { payload: education }) => {
      return {
        ...state,
        education,
        isLoading: false
      };
    },
    [ACTIONS.GET_EDUCATION_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        apiError,
        isLoading: false
      };
    },
    [ACTIONS.GET_CONFIGS]: (state) => {
      return {
        ...state
      };
    },
    [ACTIONS.GET_CONFIGS_SUCCESS]: (state, { payload: configs }) => {
      return {
        ...state,
        configs
      };
    },
    [ACTIONS.GET_CONFIGS_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        apiError
      };
    },
    [ACTIONS.SEND_PUSH_NOTIFICATION]: (state) => {
      return {
        ...state
      };
    },
    [ACTIONS.SEND_PUSH_NOTIFICATION_SUCCESS]: (state) => {
      return {
        ...state
      };
    },
    [ACTIONS.SEND_PUSH_NOTIFICATION_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        apiError
      };
    },
    [ACTIONS.SEND_EMAIL_BY_TEMPLATE]: (state) => {
      return {
        ...state
      };
    },
    [ACTIONS.SEND_EMAIL_BY_TEMPLATE_SUCCESS]: (state) => {
      return {
        ...state
      };
    },
    [ACTIONS.SEND_EMAIL_BY_TEMPLATE_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        apiError
      };
    },
    [ACTIONS.GET_DASHBOARD]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_DASHBOARD_SUCCESS]: (state, { payload: dashboard }) => {
      return {
        ...state,
        dashboard,
        isLoading: false
      };
    },
    [ACTIONS.GET_DASHBOARD_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        apiError,
        isLoading: false
      };
    },
    [ACTIONS.GET_CITIES]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_CITIES_SUCCESS]: (state, { payload: cities }) => {
      return {
        ...state,
        cities,
        isLoading: false
      };
    },
    [ACTIONS.GET_CITIES_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        apiError,
        isLoading: false
      };
    },
    [ACTIONS.GET_PROFESSIONS]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_PROFESSIONS_SUCCESS]: (state, { payload: professions }) => {
      return {
        ...state,
        professions,
        isLoading: false
      };
    },
    [ACTIONS.GET_PROFESSIONS_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        apiError,
        isLoading: false
      };
    },
    [ACTIONS.UPLOAD_FILE]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.UPLOAD_FILE_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false
      };
    },
    [ACTIONS.UPLOAD_FILE_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        apiError,
        isLoading: false
      };
    },
  },
  initialState
);
