import React from "react";

import HiringConfirmationsTable from "../../components/HiringConfirmations";

const Confirmations = () => {
  return (
    <>
      <HiringConfirmationsTable />
    </>
  );
}

export default Confirmations;