import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from "connected-react-router";
import rootSaga from '../modules/sagas/rootSaga';
import reducers from '../modules/reducers';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);

const store = createStore(
  reducers(history),
  applyMiddleware(sagaMiddleware, routeMiddleware)
);

sagaMiddleware.run(rootSaga);

export default store;
