import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Table,
  Grid,
  Dropdown,
} from 'semantic-ui-react';
import moment from 'moment';

import HiringValueAddModal from '../HiringValueAddModal';

import { HiringActions } from "../../modules/actions";

const HiringValuesTable = () => {
  const dispatch = useDispatch();

  const { hiringValues } = useSelector(state => state.hiring);
  const [selectedHiringValue, setSelectedHiringValue] = useState(null);
  const [showHiringValueAdd, setShowHiringValueAdd] = useState(false);
  const [type, setType] = useState('add');

  useEffect(() => {
    dispatch(HiringActions.GET_HIRING_VALUES());
  }, [dispatch]);

  const onSave = (data) => {
    if (type === 'add') {
      dispatch(HiringActions.POST_HIRING_VALUE(data));
    } else {
      dispatch(HiringActions.PATCH_HIRING_VALUE({ id: selectedHiringValue.id, data }));
    }
    setSelectedHiringValue(null);
    setShowHiringValueAdd(false);
  }

  const onDelete = () => {
    dispatch(HiringActions.DELETE_HIRING_VALUE(selectedHiringValue.id));
  }

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Button
              primary
              onClick={() => {
                setSelectedHiringValue(null);
                setType('add');
                setShowHiringValueAdd(true);
              }}
            >
              Adicionar
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>DESCRIPCIÓN</Table.HeaderCell>
                  <Table.HeaderCell>FECHA</Table.HeaderCell>
                  <Table.HeaderCell>VALOR</Table.HeaderCell>
                  <Table.HeaderCell>ACTIVO</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {hiringValues.map(h => {
                  return (
                    <Table.Row key={h.id}>
                      <Table.Cell width={'4'}>{h.description}</Table.Cell>
                      <Table.Cell>{moment(h.date).format('DD/MM/YYYY')}</Table.Cell>
                      <Table.Cell>{h.value}</Table.Cell>
                      <Table.Cell>{h.enabled ? 'Si' : 'No'}</Table.Cell>
                      <Table.Cell width={2}>
                        {<Dropdown item text='Acciones' onClick={() => setSelectedHiringValue(h)}>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setType('edit');
                                setSelectedHiringValue(h);
                                setShowHiringValueAdd(true);
                              }}
                            >
                              Editar
                            </Dropdown.Item>

                            <Dropdown.Item
                              onClick={() => {
                                setSelectedHiringValue(h);
                                onDelete();
                              }}
                            >
                              Eliminar
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>}

                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>

              {/* <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='6'>
                    <Menu floated='right' pagination>
                      <Menu.Item as='a' icon>
                        <Icon name='chevron left' />
                      </Menu.Item>
                      <Menu.Item as='a'>1</Menu.Item>
                      <Menu.Item as='a'>2</Menu.Item>
                      <Menu.Item as='a'>3</Menu.Item>
                      <Menu.Item as='a'>4</Menu.Item>
                      <Menu.Item as='a' icon>
                        <Icon name='chevron right' />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer> */}
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <HiringValueAddModal
        isOpen={showHiringValueAdd}
        onSave={onSave}
        onCancel={() => setShowHiringValueAdd(false)}
        hiringValue={selectedHiringValue}
        type={type}
      />
    </>
  );
}

export default HiringValuesTable;
