import { takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { CouponActions } from '../actions';

export function* getCoupons( api ) {
  try {
    const { ok, status, data } = yield call(api.getCoupons);

    if(ok && status === 200) {
      yield put( CouponActions.GET_COUPONS_SUCCESS(data));
    } else {
      throw new Error( 'Error desde el servidor' );
    }
  } catch(e) {
    yield put( CouponActions.GET_COUPONS_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* postCoupons( api, { payload } ) {
  try {
    const { ok, status, data } = yield call(api.postCoupons, payload);

    if(ok && status === 200) {
      yield put( CouponActions.POST_COUPONS_SUCCESS(data));
      yield call(getCoupons, api);
    } else {
      throw new Error( 'Error desde el servidor' );
    }
  } catch(e) {
    yield put( CouponActions.POST_COUPONS_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* postHolperUserCoupons( api, { payload } ) {
  try {
    const { ok, status, data } = yield call(api.postHolperUserCoupons, payload);

    if(ok && status === 200) {
      yield put( CouponActions.POST_HOLPER_COUPONS_SUCCESS(data));
      yield call(getCoupons, api);
    } else {
      throw new Error( 'Error desde el servidor' );
    }
  } catch(e) {
    yield put( CouponActions.POST_HOLPER_COUPONS_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}


export function* watchCoupon( api ) {
  yield takeLatest( 'GET_COUPONS', getCoupons, api );
  yield takeLatest( 'POST_COUPONS', postCoupons, api );
  yield takeLatest( 'POST_HOLPER_COUPONS', postHolperUserCoupons, api );
}
