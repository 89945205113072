import React from 'react';

const HolperDetails = () => {
  return (
    <div>
      HolperDetails
    </div>
  );
}

export default HolperDetails;
