import React from 'react';
import { Icon, Menu, Segment, Sidebar as SemanticSidebar } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import './styles.scss';

const Sidebar = ({ content }) => (
  <SemanticSidebar.Pushable as={'div'} className='segment-sidebar'>
    <SemanticSidebar
      as={Menu}
      animation='push'
      vertical
      visible
      width='wide'
    >
      <Menu.Item as={NavLink} to='/statistics'>
        <Icon name='sitemap' />
        Dashboard
      </Menu.Item>
      <Menu.Item as={NavLink} to='/holpers'>
        <Icon name='user circle' />
        Holpers
      </Menu.Item>
      <Menu.Item as={NavLink} to='/clients'>
        <Icon name='user circle outline' />
        Clientes
      </Menu.Item>
      {/* <Menu.Item as={NavLink} to='/hirings'>
        <Icon name='calendar alternate outline' />
        Contrataciones
      </Menu.Item> */}
      <Menu.Item as={NavLink} to='/coupons'>
        <Icon name='ticket alternate' />
        Cupones
      </Menu.Item>
      <Menu.Item as={NavLink} to='/payments'>
        <Icon name='dollar' />
        Pagos
      </Menu.Item>
      <Menu.Item as={NavLink} to='/notifications'>
        <Icon name='comment outline' />
        Notificaciones
      </Menu.Item>
      <Menu.Item as={NavLink} to='/clients-debts'>
        <Icon name='money bill alternate outline' />
        Deudas Clientes
      </Menu.Item>
      <Menu.Item as={NavLink} to='/hiring-values'>
        <Icon name="table" />
        Tabla de Precios
      </Menu.Item>
      <Menu.Item as={NavLink} to='/confirmations'>
        <Icon name='check circle outline' />
        Confirmaciones
      </Menu.Item>
      <Menu.Item as={NavLink} to='/payments'>
        <Icon name='dollar' />
        Pagos
      </Menu.Item>
      {/* <Menu.Item as={NavLink} to='/cancellations'>
        <Icon name='ban' />
        Cancelaciones
      </Menu.Item>
      <Menu.Item as={NavLink} to='/payments'>
        <Icon name='dollar' />
        Pagos
      </Menu.Item>
      <Menu.Item as={NavLink} to='/trainings'>
        <Icon name='address book outline' />
        Capacitaciones
      </Menu.Item>
      <Menu.Item as={NavLink} to='/awards'>
        <Icon name='trophy' />
        Reconocimientos
      </Menu.Item>
      <Menu.Item as={NavLink} to='/configs'>
        <Icon name='cogs' />
        Configuración App
      </Menu.Item>
      <Menu.Item as={NavLink} to='/helps'>
        <Icon name='question circle outline' />
        Ayuda
      </Menu.Item>
      <Menu.Item as={NavLink} to='/users'>
        <Icon name='user outline' />
        Usuarios
      </Menu.Item> */}
    </SemanticSidebar>

    <SemanticSidebar.Pusher className='sidebar-pusher'>
      <Segment basic className='segment-sidebar-pusher'>
        {content}
      </Segment>
    </SemanticSidebar.Pusher>
  </SemanticSidebar.Pushable>
)

export default Sidebar;
