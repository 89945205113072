import React from 'react';
// import ReactExport from "react-export-excel";
import { Button } from 'semantic-ui-react';

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExcelHolper = ({ items }) => {
  return (
    // <ExcelFile element={<Button basic circular>
    //   <Button.Content visible>Descargar Excel</Button.Content>
    // </Button>}>
    //   <ExcelSheet data={items} name="Holpers">
    //     <ExcelColumn label="Nombre" value="name" />
    //     <ExcelColumn label="Celular" value="phone" />
    //     <ExcelColumn label="Correo" value="email" />
    //   </ExcelSheet>
    // </ExcelFile>

    <Button basic circular>
      <Button.Content visible>Descargar Excel</Button.Content>
    </Button>
  );
}

export default ExcelHolper;