import React, { useState } from 'react';
import { Modal, Image } from 'semantic-ui-react';

const ImageViewer = ({src}) => {
  const [open, setOpen] = useState(false);
  const [notFound, setNotFound] = useState(false);

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => {
        if(!notFound) {
          setOpen(true);
        }
      }}
      open={open}
      size='large'
      trigger={(
        <Image
          src={src}
          size='small'
          centered
          onError={i => {
            i.target.src='https://react.semantic-ui.com/images/wireframe/image.png';
            setNotFound(true);
          }}
        />
      )}
    >
      <Modal.Content image>
        <Image
          src={src}
          size='big'
          centered
        />
      </Modal.Content>
    </Modal>
  );
};

export default ImageViewer;
