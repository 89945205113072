import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Message, Grid, Segment, Image, Card, Icon, Table } from "semantic-ui-react";
import { HiringActions } from "../../modules/actions";
import { showImage, currencyFormat } from "../../common/utils";
import { HiringTable } from "../../components";

const PaymentDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const holperDebt = useSelector(state => state.hiring.hiringDebt);

  useEffect(() => {
    dispatch(HiringActions.GET_HIRING_DEBT(
      {
        id,
        filter: { include: ['user', { paymentHirings: { hiring: ['hiringExtraTasks', 'client', 'professional'] } }] }
      }))
  }, []);

  const getStatusLabel = () => {
    let label = 'Cargando...';

    switch (holperDebt.status) {
      case 'pending':
        label = 'PENDIENTE DE PAGO';
        break;
      case 'paid':
        label = 'PAGADO';
      default:
        break;
    }

    return label;
  }

  return (
    <Segment>
      <Grid columns={1} stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Message textAlign="center" negative={holperDebt.status === 'pending'} success={holperDebt.status === 'paid'}>
              <Message.Header>{getStatusLabel()}</Message.Header>
              {holperDebt.status === 'pending' && <p>Fecha Máxima de Pago: <strong>{moment(holperDebt.createdAt).add(15, 'days').format('DD-MM-YYYY')}</strong> </p>}
              {holperDebt.status === 'paid' && <p>Fecha de Pago: <strong>{moment(holperDebt.paymentDate).format('DD-MM-YYYY')}</strong> </p>}
            </Message>
          </Grid.Column>
          <Grid.Column>
            <Message size="huge" textAlign="center" negative={holperDebt.status === 'pending'} success={holperDebt.status === 'paid'}>
              <Message.Header>Valor a Pagar: {currencyFormat(holperDebt.value)}</Message.Header>
            </Message>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} style={{ paddingTop: 10 }}>
          <Grid.Column width={4}>
            <Card>
              <Image src={holperDebt.user ? showImage(holperDebt.user.avatar, 'holper-profile-images') : ''} wrapped ui={false} />
              <Card.Content>
                <Card.Header>{holperDebt.user ? holperDebt.user.name : ''}</Card.Header>
                <Card.Meta>
                  <span className='date'>Inicio desde {holperDebt.user ? moment(holperDebt.user.createdAt).format('LLLL') : ''}</span>
                </Card.Meta>
                {/* <Card.Description>
                      Matthew is a musician living in Nashville.
                    </Card.Description> */}
              </Card.Content>
              <Card.Content extra>
                <a>
                  <Icon name='user' />
                  {holperDebt.user ? holperDebt.user.hiringCount : 0} Contrataciones
                </a>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column width={12}>
            {holperDebt.image && <Image size="huge" src={showImage(holperDebt.image, 'holper-payment')} />}

            <div>
              {holperDebt.description}
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column>
            <HiringTable
              items={holperDebt.paymentHirings ? holperDebt.paymentHirings.map(p => p.hiring) : []}
              type='completed'
              cities={[]}
            />
          </Grid.Column>
        </Grid.Row>

      </Grid>
    </Segment>
  );
}

export default PaymentDetails;