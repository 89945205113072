import React from 'react';

const Help = () => {

  return (
    <div>
      Help
    </div>
  );
};

export default Help;
