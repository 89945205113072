import React  from 'react';
import { Modal as SemanticModal, Button } from 'semantic-ui-react';

const Modal = ({ visible, title, description, cancelText, acceptText, onCancel, onAccept, confirmOnly, size, children }) => {
  return(
    <SemanticModal
      dimmer='blurring'
      open={visible}
      onClose={onCancel}
      size={size || 'small'}
    >
      <SemanticModal.Header>{title}</SemanticModal.Header>
      <SemanticModal.Content>{description}</SemanticModal.Content>
      <SemanticModal.Content>{children}</SemanticModal.Content>
      <SemanticModal.Actions>
        {!confirmOnly && (
          <Button negative onClick={onCancel}>{cancelText}</Button>
        )}
        <Button positive onClick={onAccept}>{acceptText}</Button>
      </SemanticModal.Actions>
    </SemanticModal>
  );
};

export default Modal;
