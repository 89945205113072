import { takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { UserActions, CommonActions } from '../actions';
import { get } from 'lodash';

export function* init(api) {
  try {
    const storage = yield call([localStorage, 'getItem'], 'login');

    if (!storage) {
      yield put(UserActions.INIT_ERROR());
      yield put(push('/login'));
    } else {
      const storageObj = JSON.parse(storage);
      const userId = get(storageObj, 'userId', null);
      const { ok, status, data } = yield call(api.getUser, userId);
      const { ok: okConfigs, status: statusConfigs, data: dataConfigs } = yield call(api.getConfigs);

      if (ok && status === 200 && okConfigs && statusConfigs === 200) {
        yield put(UserActions.INIT_SUCCESS(data));
        yield put(CommonActions.GET_CONFIGS_SUCCESS(dataConfigs.length ? dataConfigs[0] : dataConfigs));
      } else {
        yield put(UserActions.INIT_ERROR());
        throw new Error('Error desde el servidor');
      }
    }
  } catch (e) {
    yield put(UserActions.INIT_ERROR());
  }
}

export function* login(api, { payload }) {
  try {
    const { ok, status, data } = yield call(api.login, payload);

    if (ok && status === 200) {
      yield put(UserActions.LOGIN_SUCCESS());
      yield call([localStorage, 'setItem'], 'login', JSON.stringify(data));

      const responseUser = yield call(api.getUser, data.userId);
      const { ok: okConfigs, status: statusConfigs, data: dataConfigs } = yield call(api.getConfigs);

      if (responseUser.ok && responseUser.status === 200 && okConfigs && statusConfigs === 200) {
        yield put(UserActions.LOGIN_SUCCESS(responseUser.data));
        yield put(CommonActions.GET_CONFIGS_SUCCESS(dataConfigs.length ? dataConfigs[0] : dataConfigs));
        yield put(push('/statistics'));
      } else {
        yield put(UserActions.LOGIN_ERROR());
        throw new Error('Error desde el servidor');
      }
    } else {
      throw new Error('Correo ó Password incorrectos');
    }
  } catch (e) {
    yield put(UserActions.LOGIN_ERROR());
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* logout(api) {
  try {
    const storage = yield call([localStorage, 'getItem'], 'login');
    const { ok, status } = yield call(api.logout, { accessToken: JSON.parse(storage).id });

    if (ok && status === 200) {
      yield put(UserActions.LOGOUT_SUCCESS());

      yield put(push('/login'));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(UserActions.LOGOUT_ERROR());
  }
}

export function* getProfessionals(api) {
  try {
    const { ok, status, data } = yield call(api.getUsers, { include: ['address', 'addresses'], where: { professionId: { neq: null } }, order: 'name' });

    if (ok && status === 200) {
      yield put(UserActions.GET_PROFESSIONALS_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(UserActions.GET_PROFESSIONALS_ERROR(e));
  }
}

export function* getClients(api) {
  try {
    const { ok, status, data } = yield call(api.getUsers, { where: { and: [{ username: { neq: 'admin' } }, { enabled: true }, { professionId: { eq: null } }] }, order: 'createdAt DESC' });

    if (ok && status === 200) {
      yield put(UserActions.GET_CLIENTS_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(UserActions.GET_CLIENTS_ERROR(e));
  }
}

export function* getClientDebts(api, { payload: filter }) {
  try {
    const { ok, status, data } = yield call(api.getClientDebts, filter);

    if (ok && status === 200) {
      yield put(UserActions.GET_CLIENT_DEBTS_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(UserActions.GET_CLIENT_DEBTS_ERROR(e));
  }
}

export function* getUser(api, { payload: { userId, filter } }) {
  try {
    const { ok, status, data } = yield call(api.getUser, userId, filter);

    if (ok && status === 200) {
      yield put(UserActions.GET_USER_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(UserActions.GET_USER_ERROR(e));
  }
}

export function* updateUser(api, { payload: user }) {
  try {
    const { ok, status, data } = yield call(api.updateUser, user);

    if (ok && status === 200) {
      yield put(UserActions.UPDATE_USER_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(UserActions.UPDATE_USER_ERROR(e));
  }
}

export function* sendNotifications(api, { payload: { filter, isClient, title, body, data, badge, push, sms } }) {
  try {
    const { ok, status } = yield call(api.sendNotifications, filter, isClient, title, body, data, badge, push, sms);

    if (ok && status === 200) {
      yield put(UserActions.SEND_NOTIFICATION_SUCCESS());
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(UserActions.SEND_NOTIFICATION_ERROR(e));
  }
}

export function* updateClientDebts(api, { payload }) {
  try {
    const { ok, status, data } = yield call(api.updateClientDebts, payload.id, payload.data);

    if (ok && status === 200) {
      yield put(UserActions.UPDATE_CLIENT_DEBTS_SUCCESS(data));

      yield call(getClientDebts, api, {
        payload: {
          filter: {
            include: ['user', { hiring: ['client', 'professional'] }],
            order: 'createdAt DESC'
          }
        }
      });
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(UserActions.UPDATE_CLIENT_DEBTS_ERROR(e));
  }
}

export function* watchUser(api) {
  yield takeLatest('LOGIN', login, api);
  yield takeLatest('INIT', init, api);
  yield takeLatest('LOGOUT', logout, api);
  yield takeLatest('GET_PROFESSIONALS', getProfessionals, api);
  yield takeLatest('GET_CLIENTS', getClients, api);
  yield takeLatest('GET_CLIENT_DEBTS', getClientDebts, api);
  yield takeLatest('GET_USER', getUser, api);
  yield takeLatest('UPDATE_USER', updateUser, api);
  yield takeLatest('SEND_NOTIFICATION', sendNotifications, api);
  yield takeLatest('UPDATE_CLIENT_DEBTS', updateClientDebts, api);
}
