import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Tab } from 'semantic-ui-react';
import { orderBy, isEmpty } from 'lodash';
import { UserActions, CommonActions } from '../../modules/actions';
import { HolpersTables, Loader } from '../../components';

const Holpers = () => {
  const dispatch = useDispatch();
  const { isLoading, professionals } = useSelector(state => state.user);
  const { cities: cityArray } = useSelector(state => state.common);

  const cities = [{ id: '1000', name: 'Todos' }, ...cityArray];

  useEffect(() => {
    dispatch(UserActions.GET_PROFESSIONALS());
    dispatch(CommonActions.GET_CITIES());
  }, [dispatch]);

  const panes = [
    {
      menuItem: 'Activos',
      render: () => (
        <HolpersTables
          items={professionals.filter(professional => (
            professional.basicServiceStatus === 'approved' &&
            professional.documentsStatus === 'approved' &&
            professional.bankAccountStatus === 'approved' &&
            professional.socialSecurityStatus === 'approved' &&
            professional.enabled && professional.step === 'DrawerRouter'))}
          type='active'
          cities={cities}
        />
      ),
    }, {
      menuItem: 'Suspendidos',
      render: () => <HolpersTables cities={cities} items={professionals.filter(professional => !professional.enabled)} type='disabled' />,
    }, {
      menuItem: 'Inactivos',
      render: () => <HolpersTables cities={cities} items={professionals.filter(professional => (
        !professional.available &&
        professional.enabled &&
        (professional.basicServiceStatus === 'approved' &&
          professional.documentsStatus === 'approved' &&
          professional.bankAccountStatus === 'approved' &&
          professional.socialSecurityStatus === 'approved'
        )))} type='inactive' />,
    }, {
      menuItem: 'Por verificar',
      render: () => (
        <HolpersTables
          items={orderBy(professionals.filter(professional => (
            ((professional.basicServiceStatus !== 'rejected') &&
              (professional.documentsStatus !== 'rejected') &&
              (professional.bankAccountStatus !== 'rejected') &&
              (professional.socialSecurityStatus !== 'rejected')
            ) &&
            !(professional.basicServiceStatus === 'approved' &&
              professional.documentsStatus === 'approved' &&
              professional.bankAccountStatus === 'approved' &&
              professional.socialSecurityStatus === 'approved'
            ) && professional.enabled
          )), ['createdAt'], ['desc'])}
          type='verify'
          cities={cities}
        />
      ),
    },
    {
      menuItem: 'Docs Rechazados',
      render: () => (
        <HolpersTables
          items={orderBy(professionals.filter(professional => (
            (professional.basicServiceStatus === 'rejected' ||
              professional.documentsStatus === 'rejected' ||
              professional.bankAccountStatus === 'rejected' ||
              professional.socialSecurityStatus === 'rejected') &&
            professional.enabled
          )), ['createdAt'], ['desc'])}
          type='rejected'
          cities={cities}
        />
      ),
    }
  ]

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Header as='h1' textAlign='center'>HOLPERS</Header>

      <Tab menu={{ pointing: true }} panes={panes} />
    </div>
  );
};

export default Holpers;
