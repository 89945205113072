import React, { useState } from 'react';
import {Button, Form, Grid, Header, Icon, Image, Segment} from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { UserActions } from '../../modules/actions';
import { validEmail } from '../../common/utils';
import './styles.scss'

import HolperIcon from '../../assets/logos/holper-icon.png';

const Login = () => {
  const dispatch = useDispatch();
  const [ email, setEmail ] = useState();
  const [ emailError, setEmailError ] = useState(false);
  const [ password, setPassword ] = useState();
  const [ passwordError, setPasswordError ] = useState(false);

  const login = () => {
    setEmailError(false);
    setPasswordError(false);

    if(email && validEmail(email) && password) {
      dispatch(UserActions.LOGIN({ email, password }));
    }

    if(!email) {
      setEmailError('Por favor ingrese el correo electrónico');
    }

    if(!validEmail(email)) {
      setEmailError('Por favor ingrese un correo electrónico válido');
    }

    if(!password) {
      setPasswordError('Por favor ingrese la contraseña');
    }
  };

  return (
    <Grid textAlign='center' className='login-container' verticalAlign='middle'>
      <Grid.Column className='login-form'>
        <Image src={HolperIcon} size='small' centered />
        <Header as='h5' color='grey' textAlign='center'>
          ponte cómodo...
        </Header>
        <Form size='large' onSubmit={login}>
          <Segment>
            <Form.Input
              fluid
              icon='user'
              iconPosition='left'
              placeholder='E-mail address'
              name='email'
              value={email}
              onChange={(e, { value }) => setEmail(value)}
              error={emailError}
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              name='password'
              value={password}
              onChange={(e, { value }) => setPassword(value)}
              error={passwordError}
            />

            <Button animated='fade' fluid size='large' color='green'>
              <Button.Content visible>Login</Button.Content>
              <Button.Content hidden>
                <Icon name='sign in' />
              </Button.Content>
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
}

export default Login;
