import { handleActions } from 'redux-actions';
import { HelpActions as ACTIONS } from '../actions';

const initialState = {
  faq: [],
  isLoading: true,
  apiError: ''
};

export const helpReducer = handleActions({
    [ACTIONS.GET_FAQ]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.GET_FAQ_SUCCESS]: (state, { payload: faq }) => {
      return {
        ...state,
        faq,
        isLoading: false
      };
    },
    [ACTIONS.GET_FAQ_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        apiError,
        isLoading: false
      };
    },
    [ACTIONS.DELETE_FAQ]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.POST_FAQ]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.POST_FAQ_SUCCESS]: (state, { payload: faq }) => {
      return {
        ...state,
        faq: [ ...state.faq, faq ],
        isLoading: false
      };
    },
    [ACTIONS.POST_FAQ_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        apiError,
        isLoading: false
      };
    },
    [ACTIONS.UPDATE_FAQ]: (state) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [ACTIONS.UPDATE_FAQ_SUCCESS]: (state) => {
      return {
        ...state,
        isLoading: false
      };
    },
    [ACTIONS.UPDATE_FAQ_ERROR]: (state, { error: apiError }) => {
      return {
        ...state,
        apiError,
        isLoading: false
      };
    },
  },
  initialState
);
