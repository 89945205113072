import { create } from 'apisauce';
import { get } from 'lodash';
import { API_URL, releaseChannel } from './constants';

export default (baseURL = API_URL) => {
  const api = create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache, no-store',
      'Pragma': 'no-cache',
      'Expires': 0
    }
  });

  api.addRequestTransform(request => {
    const user = localStorage.getItem('login');

    request.headers['Authorization'] = get(JSON.parse(user), 'id', null);
  });

  const login = ({ email, password }) => {
    return api.post('/HolperUsers/login', { email, password, realm: 'admin' });
  };

  const logout = async (data) => {
    return api.post("HolperUsers/logout", data);
  };

  const getUsers = (filter) => {
    return api.get('/HolperUsers', { filter });
  };

  const getUser = (id, filter) => {
    if (!id) return;

    return api.get(`/HolperUsers/${id}`, { filter });
  };

  const updateUser = (data) => {
    return api.patch(`/HolperUsers/${data.id}`, data);
  };

  const getFaq = (filter) => {
    return api.get(`/HelpCenters`, { filter });
  };

  const deleteFaq = (id) => {
    return api.delete(`/HelpCenters/${id}`,);
  };

  const postFaq = (data) => {
    return api.post(`/HelpCenters`, data);
  };

  const updateFaq = (data) => {
    return api.patch(`/HelpCenters/${data.id}`, data);
  };

  const getEducation = (filter = {}) => {
    return api.get('Education', { filter });
  };

  const getHirings = (filter = {}) => {
    return api.get('Hirings', { filter });
  };

  const getHiring = async (id) => {
    return api.get(`/Hirings/${id}`, {
      filter: {
        include: [
          'professional',
          { client: [{ favorites: ['favorite'] }] },
          'profession',
          'payment',
          'hiringExtraTasks',
          'hiringNotifications',
        ]
      }
    });
  };

  const patchHiring = (data) => {
    return api.patch(`/Hirings/${data.id}`, data);
  };

  const getConfigs = (filter = {}) => {
    return api.get('Configs', filter);
  };

  const postHiringClose = async (id, cash) => {
    return api.post(`/Hirings/${id}/close`, { cash });
  };

  const postHiringCancel = async (id) => {
    return api.post(`/Hirings/${id}/cancel`);
  };

  const sendPushNotificationByUserId = (userId, data) => {
    return api.post(`HolperUsers/${userId}/sendPushNotification`, { data });
  };

  const sendEmailByTemplate = (data) => {
    return api.get('EmailTemplates/send', data);
  }

  const getDashboard = (data = {}) => {
    return api.get('Dashboards/dashboard', data);
  };

  const getHiringDeletedIncluded = (id) => {
    return api.get(`/Hirings/getHiring`, { id });
  };

  const hiringEnable = (id, professionalId, date) => {
    return api.post(`/Hirings/enable`, { id, professionalId, date });
  };

  const resendHiring = (id) => {
    return api.post(`/Hirings/resend`, { id });
  };

  const getCities = () => {
    return api.get(`/Cities`);
  };

  const getCoupons = () => {
    return api.get(`/Coupons`);
  };

  const getProfessions = () => {
    return api.get(`/Professions`);
  };

  const postCoupons = (data) => {
    return api.post(`/Coupons`, data);
  };

  const postHolperUserCoupons = (data) => {
    return api.post(`/HolperUserCoupons`, data);
  };

  const getHiringDebts = (filter = {}) => {
    return api.get(`/HolperDebts`, filter);
  };

  const getHiringDebt = (id, filter) => {
    return api.get(`/HolperDebts/${id}`, { filter });
  };

  const updateHiringDebts = (id, data) => {
    return api.patch(`/HolperDebts/${id}`, data);
  };

  const uploadFile = (file, container, name) => {
    const data = new FormData();

    data.append('files', file, name || `${new Date().getTime()}-${file.name}`);

    return api.post(`/Containers/${container}-${releaseChannel}/upload`, data);
  };

  const postPay = (id, description, image) => {
    return api.post(`/HolperDebts/${id}/pay`, { description, image });
  };

  const sendNotifications = (filter, isClient, title, body, data, badge = 1, push, sms) => {
    return api.post(`/HolperUsers/sendNotifications`, { filter, isClient, title, body, data, badge, push, sms });
  };

  const getClientDebts = (filter = {}) => {
    return api.get(`/HiringDebts`, { filter });
  };

  const updateClientDebts = (id, data) => {
    return api.patch(`/HiringDebts/${id}`, data);
  };

  const getHiringValues = (filter = { order: 'date' }) => {
    return api.get(`/HiringValues`, { filter });
  };

  const postHiringValue = (data) => {
    return api.post(`/HiringValues`, data);
  };

  const updateHiringValue = (id, data) => {
    return api.patch(`/HiringValues/${id}`, data);
  };

  const deleteHiringValue = (id) => {
    return api.delete(`/HiringValues/${id}`);
  };

  return {
    login,
    logout,
    getUsers,
    getUser,
    updateUser,
    getFaq,
    deleteFaq,
    postFaq,
    updateFaq,
    getEducation,
    getHirings,
    getHiring,
    patchHiring,
    getConfigs,
    postHiringClose,
    postHiringCancel,
    sendPushNotificationByUserId,
    sendEmailByTemplate,
    getDashboard,
    getHiringDeletedIncluded,
    hiringEnable,
    resendHiring,
    getCities,
    getCoupons,
    getProfessions,
    postCoupons,
    postHolperUserCoupons,
    getHiringDebts,
    uploadFile,
    updateHiringDebts,
    postPay,
    getHiringDebt,
    sendNotifications,
    getClientDebts,
    updateClientDebts,
    getHiringValues,
    postHiringValue,
    updateHiringValue,
    deleteHiringValue,
  }
};
