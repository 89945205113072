import React, { useState, useEffect } from 'react';
import { Divider, Grid, Header, Icon, Image, Segment, Rating, Button, Modal, Table } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { showAvatar } from '../../common/utils';
import { UserActions, HiringActions, CommonActions } from '../../modules/actions';
import './styles.scss';

const HiringDetailsHeader = React.memo(({ hiring }) => {
  const dispatch = useDispatch();
  const professional = get(hiring, 'professional', null);

  const [openHolperUpdateModal, setOpenHolperUpdateModal] = useState(false);
  const { professionals } = useSelector(state => state.user);
  const [selectedHolper, setSelectedHolper] = useState({});
  const [holpers, setHolpers] = useState([]);

  useEffect(() => {
    setHolpers(professionals);
  }, [professionals])

  const updateHolper = async () => {
    if (!isEmpty(selectedHolper)) {
      await dispatch(HiringActions.PATCH_HIRING({ data: { id: hiring.id, professionalId: selectedHolper.id, isReplacementAccepted: true } }));

      const templateData = {
        emails: [hiring?.client?.email],
        name: 'updateProfessional',
        data: {
          clientName: hiring?.client?.name,
          date: moment(hiring.date).format('DD-MM-YYYY hh:mm a'),
          professionalName: selectedHolper.name
        }
      };

      await dispatch(CommonActions.SEND_EMAIL_BY_TEMPLATE(templateData));

      setOpenHolperUpdateModal(false);
    }
  }

  return (
    <Segment>
      <Grid columns={2} relaxed='very'>
        <Grid.Column textAlign='center' verticalAlign='middle'>
          <Segment.Group basic horizontal as={Segment} padded>
            <Image
              src={showAvatar(hiring?.client)}
              bordered
              circular
              size='small'
              verticalAlign='middle'
              onError={i => i.target.src = `${showAvatar({})}`}
            />
            <Header icon textAlign='center'>
              <Header.Content as='h2'>{hiring?.client?.name}</Header.Content>

              <Header.Subheader>
                <strong>Teléfono:</strong> {hiring?.client?.phone}
              </Header.Subheader>
              <Header.Subheader>
                <strong>Se creó:</strong> {moment(hiring.createdAt).format('LLLL')}
              </Header.Subheader>
            </Header>
          </Segment.Group>
        </Grid.Column>

        {professional ?
          <Grid.Column textAlign='center' verticalAlign='middle'>
            <Segment.Group basic horizontal as={Segment} padded>
              <Image
                className='header-image'
                src={showAvatar(hiring.professional)}
                bordered
                circular
                size='small'
                verticalAlign='middle'
                onError={i => i.target.src = `${showAvatar({})}`}
              />
              <Segment basic>
                <Header icon textAlign='center'>
                  <Header.Content as='h2'>{hiring.professional.name}</Header.Content>
                  <Header.Subheader>
                    <Rating maxRating={5} icon='star' size='tiny' disabled rating={hiring.professional.rating} clearable={false} />
                  </Header.Subheader>
                  <Header.Subheader>{hiring.professional.hiringCount} contrataciones</Header.Subheader>
                  <Header.Subheader>{hiring.profession.name['es']}</Header.Subheader>
                  <Header.Subheader><strong>Teléfono:</strong> {hiring.professional.phone}</Header.Subheader>
                </Header>

                {(['pending', 'onTheWay', 'arrivalConfirmed'].includes(hiring.state) && hiring.professionalId) && (
                  <Button animated='fade' basic circular onClick={() => {
                    setOpenHolperUpdateModal(true); dispatch(UserActions.GET_PROFESSIONALS());
                  }}>
                    <Button.Content visible>Cambiar Holper</Button.Content>
                    <Button.Content hidden>
                      <Icon name='arrow right' />
                    </Button.Content>
                  </Button>
                )}

              </Segment>
            </Segment.Group>
          </Grid.Column> : null}
      </Grid>

      <Divider vertical>
        <Icon name='user circle' color='grey' />
      </Divider>
      <Modal
        size={'large'}
        open={openHolperUpdateModal}
      >
        <Modal.Header>Cambiar Holper</Modal.Header>
        <Modal.Content>
          <Grid columns='equal'>
            <Grid.Row>
              <Grid.Column floated='right' width={5}>
                {/* <Input value={searchText} onChange={search} icon='search' placeholder='Busca por el nombre o cédula' iconPosition='left' transparent fluid /> */}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="table-holpers">
              <Grid.Column>
                <Table celled selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Nombre</Table.HeaderCell>
                      <Table.HeaderCell>Contrataciones</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {holpers.filter(professional => (
                      professional.basicServiceStatus === 'approved' &&
                      professional.documentsStatus === 'approved' &&
                      professional.bankAccountStatus === 'approved' &&
                      professional.socialSecurityStatus === 'approved' &&
                      professional.enabled && professional.step === 'DrawerRouter')).map(p =>
                        <Table.Row key={p.id} onClick={() => setSelectedHolper(p)} active={selectedHolper && selectedHolper.id === p.id}>
                          <Table.Cell>{p.name}</Table.Cell>
                          <Table.Cell>{p.hiringCount}</Table.Cell>
                        </Table.Row>
                      )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpenHolperUpdateModal(false)}>
            Cerrar
          </Button>
          <Button positive onClick={() => updateHolper()}>
            Actualizar
          </Button>
        </Modal.Actions>
      </Modal>

    </Segment>
  )
});

export default HiringDetailsHeader;
