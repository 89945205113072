import { createAction } from 'redux-actions';

const TYPES = {
  GET_COUPONS: 'GET_COUPONS',
  GET_COUPONS_SUCCESS: 'GET_COUPONS_SUCCESS',
  GET_COUPONS_ERROR: 'GET_COUPONS_ERROR',

  POST_COUPONS: 'POST_COUPONS',
  POST_COUPONS_SUCCESS: 'POST_COUPONS_SUCCESS',
  POST_COUPONS_ERROR: 'POST_COUPONS_ERROR',
  
  POST_HOLPER_COUPONS: 'POST_HOLPER_COUPONS',
  POST_HOLPER_COUPONS_SUCCESS: 'POST_HOLPER_COUPONS_SUCCESS',
  POST_HOLPER_COUPONS_ERROR: 'POST_HOLPER_COUPONS_ERROR'
};

const ACTIONS = {
  GET_COUPONS: createAction(TYPES.GET_COUPONS),
  GET_COUPONS_SUCCESS: createAction(TYPES.GET_COUPONS_SUCCESS),
  GET_COUPONS_ERROR: createAction(TYPES.GET_COUPONS_ERROR),

  POST_COUPONS: createAction(TYPES.POST_COUPONS),
  POST_COUPONS_SUCCESS: createAction(TYPES.POST_COUPONS_SUCCESS),
  POST_COUPONS_ERROR: createAction(TYPES.POST_COUPONS_ERROR),

  POST_HOLPER_COUPONS: createAction(TYPES.POST_HOLPER_COUPONS),
  POST_HOLPER_COUPONS_SUCCESS: createAction(TYPES.POST_HOLPER_COUPONS_SUCCESS),
  POST_HOLPER_COUPONS_ERROR: createAction(TYPES.POST_HOLPER_COUPONS_ERROR)
};

export default ACTIONS;
