import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Statistic, Grid, Header, Divider } from 'semantic-ui-react'
import { Loader } from '../../components';
import { CommonActions } from '../../modules/actions';


const Statistics = () => {
  const dispatch = useDispatch();

  const { isInitLoading, authenticatedUser } = useSelector(state => state.user);
  const { dashboard } = useSelector(state => state.common);

  useEffect(() => {
    dispatch(CommonActions.GET_DASHBOARD({ days: 1 }));

    const interval = setInterval(() => {
      dispatch(CommonActions.GET_DASHBOARD({ days: 1 }));
    }, 30000)

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  if (isInitLoading || !authenticatedUser || !dashboard) {
    return <Loader />;
  }

  return (
    <>
      {/* <Header as='h1' textAlign='center'>Estadísticas en tiempo real</Header> */}
      <Divider horizontal >De por vida</Divider>

      <Grid>
        <Grid.Row columns={4}>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Clientes registrados</Statistic.Label>
              <Statistic.Value>{dashboard.activeClients}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Holpers disponibles</Statistic.Label>
              <Statistic.Value>{dashboard.activeHolpers}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Holpers por verificar</Statistic.Label>
              <Statistic.Value>{dashboard.verifyHolpers}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones no aceptadas</Statistic.Label>
              <Statistic.Value>{dashboard.hiringsWithoutProfessionalResponse}</Statistic.Value>
            </Statistic>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={4}>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones pendientes</Statistic.Label>
              <Statistic.Value>{dashboard.pendingHirings}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones en camino</Statistic.Label>
              <Statistic.Value>{dashboard.onTheWayHirings}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones con llegadas confirmadas</Statistic.Label>
              <Statistic.Value>{dashboard.arrivalConfirmedHirings}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones en progreso</Statistic.Label>
              <Statistic.Value>{dashboard.inProgressHirings}</Statistic.Value>
            </Statistic>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={4}>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones canceladas</Statistic.Label>
              <Statistic.Value>{dashboard.canceledHirings}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones completadas</Statistic.Label>
              <Statistic.Value>{dashboard.doneHirings}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones programadas</Statistic.Label>
              <Statistic.Value>{dashboard.scheduleHirings}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones inmediatas</Statistic.Label>
              <Statistic.Value>{dashboard.immediateHirings}</Statistic.Value>
            </Statistic>
          </Grid.Column>
        </Grid.Row>

        {/* <Grid.Row columns={1}>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones no aceptadas</Statistic.Label>
              <Statistic.Value>{dashboard.hiringsWithoutProfessionalResponse}</Statistic.Value>
            </Statistic>
          </Grid.Column>
        </Grid.Row> */}
      </Grid>

      <Divider horizontal >Hoy</Divider>

      <Grid>
        <Grid.Row columns={4}>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Clientes Registrados</Statistic.Label>
              <Statistic.Value>{dashboard.activeClientsFilter}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Holpers disponibles</Statistic.Label>
              <Statistic.Value>{dashboard.activeHolpersFilter}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Holpers por verificar</Statistic.Label>
              <Statistic.Value>{dashboard.verifyHolpersFilter}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones no aceptadas</Statistic.Label>
              <Statistic.Value>{dashboard.hiringsWithoutProfessionalResponseFilter}</Statistic.Value>
            </Statistic>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={4}>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones programadas</Statistic.Label>
              <Statistic.Value>{dashboard.pendingHiringsFilter}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones en camino</Statistic.Label>
              <Statistic.Value>{dashboard.onTheWayHiringsFilter}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones con llegadas confirmadas</Statistic.Label>
              <Statistic.Value>{dashboard.arrivalConfirmedHiringsFilter}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones en progreso</Statistic.Label>
              <Statistic.Value>{dashboard.inProgressHiringsFilter}</Statistic.Value>
            </Statistic>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={2}>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones canceladas</Statistic.Label>
              <Statistic.Value>{dashboard.canceledHiringsFilter}</Statistic.Value>
            </Statistic>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <Statistic>
              <Statistic.Label>Contrataciones completadas</Statistic.Label>
              <Statistic.Value>{dashboard.doneHiringsFilter}</Statistic.Value>
            </Statistic>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default Statistics;