import Login from '../views/Auth/Login';
import Statistics from '../views/Statistics';
import {
  Holpers,
  HolperDetails,
  HolperDetailsActive,
  HolperDetailsVerify
} from '../views/Holpers';

import {
  Hirings,
  HiringDetailsProgress,
  HiringDetailsScheduled,
  HiringDetailsCompleted,
  HiringDetailsPending,
  HiringDetailsDeleted
} from '../views/Hirings';

import Payments from '../views/Payments';
import PaymentDetails from '../views/Payments/PaymentDetails';
import Clients from '../views/Clients';
import ClientsDebts from '../views/Clients/ClientDebts';
import Coupons from '../views/Coupons';
import Notifications from '../views/Notifications';
import Confirmations from '../views/Confirmations';
import { Help } from '../views/Help';
import HiringValues from '../views/HiringValues';
import PageNotFound from '../views/PageNotFound';

const privateRoutes = [{
  path: '/',
  component: Statistics,
  exact: true
}, {
  path: '/statistics',
  component: Statistics
}, {
  path: '/holpers',
  component: Holpers,
  exact: true
}, {
  path: '/holpers/:id',
  component: HolperDetails,
  exact: true
}, {
  path: '/holpers/:id/active',
  component: HolperDetailsActive
}, {
  path: '/holpers/:id/verify',
  component: HolperDetailsVerify
},
// {
//   path: '/hirings',
//   component: Hirings,
//   exact: true
// }, 
// {
//   path: '/hirings/:id/progress',
//   component: HiringDetailsProgress
// }, {
//   path: '/hirings/:id/scheduled',
//   component: HiringDetailsScheduled
// }, {
//   path: '/hirings/:id/completed',
//   component: HiringDetailsCompleted
// }, {
//   path: '/hirings/:id/pending',
//   component: HiringDetailsPending
// }, {
//   path: '/hirings/:id/deleted',
//   component: HiringDetailsDeleted
// }, {
//   path: '/hirings/:id/canceled',
//   component: HiringDetailsDeleted
// },
{
  path: '/clients',
  component: Clients
}, {
  path: '/clients-debts',
  component: ClientsDebts
}, {
  path: '/payments',
  component: Payments,
  exact: true
}, {
  path: '/payments/:id/details',
  component: PaymentDetails,
  exact: true
}, {
  path: '/helps',
  component: Help
}, {
  path: '/coupons',
  component: Coupons
}, {
  path: '/confirmations',
  component: Confirmations
}, {
  path: '/notifications',
  component: Notifications
}, {
  path: '/hiring-values',
  component: HiringValues
}, {
  component: PageNotFound
}];

const publicRoutes = [{
  path: '/login',
  component: Login
}, {
  component: PageNotFound
}];

export { publicRoutes, privateRoutes };
