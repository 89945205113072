import { takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { CommonActions } from '../actions';

export function* getEducation(api, { filter }) {
  try {
    const { ok, status, data } = yield call(api.getEducation, filter);

    if (ok && status === 200) {
      yield put(CommonActions.GET_EDUCATION_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(CommonActions.GET_EDUCATION_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* getConfigs(api) {
  try {
    const { ok, status, data } = yield call(api.getConfigs);

    if (ok && status === 200) {
      yield put(CommonActions.GET_CONFIGS_SUCCESS(data.length ? data[0] : data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(CommonActions.GET_CONFIGS_ERROR(e.message));
  }
}

export function* sendPushNotification(api, { payload: { userId, data } }) {
  try {
    const response = yield call(api.sendPushNotificationByUserId, userId, data);

    if (response.ok && response.status === 200) {
      yield put(CommonActions.SEND_PUSH_NOTIFICATION_SUCCESS());
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(CommonActions.SEND_PUSH_NOTIFICATION_ERROR(e.message));
  }
}

export function* sendEmailByTemplate(api, { payload: data }) {
  try {
    const response = yield call(api.sendEmailByTemplate, data);

    if (response.ok && response.status === 200) {
      yield put(CommonActions.SEND_EMAIL_BY_TEMPLATE_SUCCESS());
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(CommonActions.SEND_EMAIL_BY_TEMPLATE_ERROR(e.message));
  }
}

export function* getDashboard(api, { payload: dashboardData }) {
  try {
    const { ok, status, data } = yield call(api.getDashboard, dashboardData);

    if (ok && status === 200) {
      yield put(CommonActions.GET_DASHBOARD_SUCCESS(data.data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(CommonActions.GET_DASHBOARD_ERROR(e));
    yield call([localStorage, 'clear']);
    yield put(push('/login'));
  }
}

export function* getCities(api) {
  try {
    const { ok, status, data } = yield call(api.getCities);

    if (ok && status === 200) {
      yield put(CommonActions.GET_CITIES_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(CommonActions.GET_CITIES_ERROR(e.message));
  }
}

export function* getProfessions(api) {
  try {
    const { ok, status, data } = yield call(api.getProfessions);

    if (ok && status === 200) {
      yield put(CommonActions.GET_PROFESSIONS_SUCCESS(data));
    } else {
      throw new Error('Error desde el servidor');
    }
  } catch (e) {
    yield put(CommonActions.GET_CITIES_ERROR(e.message));
  }
}

export function* uploadFile(api, { payload: { data, container, name } }) {
  try {
    const { ok } = yield call(api.uploadFile, data, container, name);

    if (ok === false) {
      throw new Error('Server Error');
    }

    yield put(CommonActions.UPLOAD_FILE_SUCCESS());
  } catch (e) {
    yield put(CommonActions.UPLOAD_FILE_ERROR(e));
  }
}

export function* watchCommon(api) {
  yield takeLatest('GET_EDUCATION', getEducation, api);
  yield takeLatest('GET_CONFIGS', getConfigs, api);
  yield takeLatest('SEND_PUSH_NOTIFICATION', sendPushNotification, api);
  yield takeLatest('SEND_EMAIL_BY_TEMPLATE', sendEmailByTemplate, api);
  yield takeLatest('GET_DASHBOARD', getDashboard, api);
  yield takeLatest('GET_CITIES', getCities, api);
  yield takeLatest('GET_PROFESSIONS', getProfessions, api);
  yield takeLatest('UPLOAD_FILE', uploadFile, api);
}
