import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Comment, Image, Button, Icon } from 'semantic-ui-react';
import { UserActions } from '../../modules/actions';
import { showAvatar } from '../../common/utils';

const Header = () => {
  const dispatch = useDispatch();
  const { authenticatedUser } = useSelector( state => state.user);

  const logout = () => {
    dispatch(UserActions.LOGOUT());
  };

  return (
    <Menu stackable fixed='top'>
      <Menu.Item>
        <Image src={require('../../assets/logos/holper-icon.png')} size='small' />
      </Menu.Item>

      <Menu.Menu position='right'>
        <Menu.Item name='user'>
          <Comment.Group>
            <Comment>
              <Comment.Avatar src={showAvatar(authenticatedUser, false)} />
              <Comment.Content>
                <Comment.Text>
                  Bienvenido <Comment.Author as='span'>{ authenticatedUser.name }</Comment.Author>
                </Comment.Text>
                <Comment.Text>
                  <small color='gray'>Administrador</small>
                </Comment.Text>
              </Comment.Content>
            </Comment>
          </Comment.Group>
        </Menu.Item>

        <Menu.Item
          color='grey'
          name='sign-out'
          onClick={logout}
        >
          <Button animated='fade' basic circular>
            <Button.Content visible>Cerrar Sesión</Button.Content>
            <Button.Content hidden>
              <Icon name='log out' />
            </Button.Content>
          </Button>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

export default Header;
