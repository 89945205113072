import React from 'react';
import { Icon, Header, Segment } from 'semantic-ui-react';
import { getHiringStateName } from '../../common/utils';
import './styles.scss';

const NavigationTitle = ({label, state}) => {
  const getStateIcon = () => {
    switch (state) {
      case 'onTheWay':
      case 'arrivalConfirmed':
      case 'inProgress':
        return 'check circle outline';
      case 'canceled':
        return 'times circle outline';
      default:
        return '';
    }
  };

  const getStateColor = () => {
    switch (state) {
      case 'onTheWay':
      case 'arrivalConfirmed':
      case 'inProgress':
        return 'green';
      case 'canceled':
        return 'red';
      default:
        return 'grey';
    }
  };

  return (
    <Segment clearing basic>
      <Header color='grey' onClick={() => window.history.back()} className='navigation-title' floated='left'>
        <Icon name='chevron left' />
        <Header.Content as='h2'>{label}</Header.Content>
      </Header>

      {state && (
        <Header as='h2' icon size='small' floated='right' textAlign='right'>
          <Icon name={getStateIcon()} color={getStateColor()} />
          <Header.Subheader>
            {getHiringStateName(state)}
          </Header.Subheader>
        </Header>
      )}
    </Segment>

  )
}

export default NavigationTitle;
