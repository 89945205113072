import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Header, Icon, Modal, Radio, Checkbox } from "semantic-ui-react";
import { UserActions } from "../../modules/actions";

const Notifications = () => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [userType, setUserType] = useState('professional');
  const [push, setPush] = useState(true);
  const [sms, setSms] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const sendNotifications = () => {
    const cond = userType === 'client' ? { eq: null } : { neq: null };

    const payload = {
      filter: { where: { professionId: cond, enabled: true } },
      isClient: userType === 'client',
      title,
      body: message,
      data: {},
      badge: 1,
      push,
      sms
    }

    dispatch(UserActions.SEND_NOTIFICATION(payload));

    setTitle('');
    setMessage('');

    setConfirmModal(false);
  }

  return (
    <>
      <Form>
        <Form.Group widths={2}>
          <Form.Input
            value={title}
            label='Título'
            placeholder='Título'
            onChange={(e) => setTitle(e.target.value)}
          />
          <Form.Input
            value={message}
            label='Mensaje'
            placeholder='Mensaje'
            onChange={(e) => setMessage(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Field>
            <Checkbox
              label='Push'
              onChange={() => { setPush(!push) }}
              checked={push}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label='SMS'
              onChange={() => { setSms(!sms) }}
              checked={sms}
            />
          </Form.Field>

          <Form.Field>
            Enviar a:
          </Form.Field>
          <Form.Field>
            <Radio
              label='Profesionales'
              name='radioGroup'
              value='professional'
              checked={userType === 'professional'}
              onChange={(e, { value }) => setUserType(value)}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='Clientes'
              name='radioGroup'
              value='client'
              checked={userType === 'client'}
              onChange={(e, { value }) => setUserType(value)}
            />
          </Form.Field>
        </Form.Group>
        <Button
          type='button'
          onClick={() => setConfirmModal(true)}
          disabled={!title || !message || (!push && !sms)}
        >
          Enviar
        </Button>
      </Form>

      <Modal
        closeIcon
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        onOpen={() => setConfirmModal(true)}
      >
        <Header icon='warning' content={`Enviar Mensajes`} />
        <Modal.Content>
          <p>
            {`Seguro que desea enviar el mensaje a los ${userType === 'professional' ? 'Profesionales' : 'Clientes'}.`}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={() => setConfirmModal(false)}>
            <Icon name='remove' /> No
          </Button>
          <Button color='green' onClick={() => sendNotifications()}>
            <Icon name='checkmark' /> Sí
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default Notifications;