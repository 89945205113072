import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Icon,
  Menu,
  Table,
  Grid,
  Dropdown,
} from 'semantic-ui-react';
import moment from 'moment';

import { UserActions } from "../../modules/actions";

const ClientDebtsTable = ({ items, cities }) => {
  const dispatch = useDispatch();

  const { clientDebts } = useSelector(state => state.user);
  const [selectedDebt, setSelectedDebt] = useState(null);

  useEffect(() => {
    const filter = {
      include: ['user', { hiring: ['client', 'professional'] }],
      order: 'createdAt DESC'
    };

    dispatch(UserActions.GET_CLIENT_DEBTS(filter));
  }, [dispatch]);

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>CLIENTE</Table.HeaderCell>
                  <Table.HeaderCell>HOLPER</Table.HeaderCell>
                  <Table.HeaderCell>DESCRIPCIÓN</Table.HeaderCell>
                  <Table.HeaderCell>PAGADO</Table.HeaderCell>
                  <Table.HeaderCell>FECHA</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {clientDebts.map(c => {
                  return (
                    <Table.Row>
                      <Table.Cell >{c?.hiring?.client?.name}</Table.Cell>
                      <Table.Cell>{c?.hiring?.professional?.name}</Table.Cell>
                      <Table.Cell width={'6'}>{c.description}</Table.Cell>
                      <Table.Cell>{c.paid ? 'Si' : 'No'}</Table.Cell>
                      <Table.Cell>{moment(c.createdAt).format('DD-MM-YYYY HH:mm:ss')}</Table.Cell>
                      <Table.Cell width={2}>
                        {!c.paid && <Dropdown item text='Acciones' onClick={() => setSelectedDebt(c)}>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                dispatch(UserActions.UPDATE_CLIENT_DEBTS({ id: c.id, data: { paid: true } }));
                              }}
                            >
                              Pagar
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>}

                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='6'>
                    <Menu floated='right' pagination>
                      <Menu.Item as='a' icon>
                        <Icon name='chevron left' />
                      </Menu.Item>
                      <Menu.Item as='a'>1</Menu.Item>
                      <Menu.Item as='a'>2</Menu.Item>
                      <Menu.Item as='a'>3</Menu.Item>
                      <Menu.Item as='a'>4</Menu.Item>
                      <Menu.Item as='a' icon>
                        <Icon name='chevron right' />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default ClientDebtsTable;
