import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Button, Modal, Grid, Table, Label } from 'semantic-ui-react';
import { HiringActions, UserActions } from '../../modules/actions';
import { Loader, HiringDetailsHeader, HiringInformation } from '../../components';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';

const HiringDetailsDeleted = ({ match }) => {
  const dispatch = useDispatch();

  const { isLoading, selectedHiring } = useSelector(state => state.hiring);
  const [openHolperUpdateModal, setOpenHolperUpdateModal] = useState(false);
  const { professionals } = useSelector(state => state.user);
  const [selectedHolper, setSelectedHolper] = useState({});
  const [selectedDate, setSelectedDate] = useState('');
  const [holpers, setHolpers] = useState([]);

  useEffect(() => {
    const hiringId = get(match, 'params.id', null);

    dispatch(HiringActions.GET_HIRING_DELETED_INCLUDED({ hiringId }));
  }, [dispatch, match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(UserActions.GET_PROFESSIONALS());
  }, [dispatch]);

  useEffect(() => {
    setHolpers(professionals)
  }, [professionals])

  const enableHiring = async () => {
    if (!isEmpty(selectedHolper)) {
      await dispatch(HiringActions.POST_HIRING_ENABLE({ id: selectedHiring.id, professionalId: selectedHolper.id, date: moment(selectedDate).utc().format() || selectedHiring.date }));
      setOpenHolperUpdateModal(false);
    }
  }

  if (isLoading || !selectedHiring || !selectedHiring.hasOwnProperty('id')) {
    return <Loader />;
  }

  return (
    <div>
      <Header as='h1' color='grey' textAlign='center'>
        Contratación Expirada
        <div className="in-progress-border" />
      </Header>

      <Button.Group basic>
        <Button onClick={() => setOpenHolperUpdateModal(true)}>Habilitar Contratación</Button>
        {/* <Button>Two</Button>
        <Button>Three</Button> */}
      </Button.Group>

      <HiringDetailsHeader hiring={selectedHiring} />

      <HiringInformation hiring={selectedHiring} />

      <Modal
        size={'large'}
        open={openHolperUpdateModal}
      >
        <Modal.Header>
          <Label size={"large"}>
            {selectedHiring.date ? `La fecha inicial es: ${moment(selectedHiring.date).format('LLLL')}` : 'Contratacíon Inmediata'}
          </Label>
        </Modal.Header>
        <Modal.Content>
          <Grid columns='equal'>
            <Grid.Row>
              <Grid.Column floated='right'>
                <DateTimeInput
                  dateFormat="YYYY-MM-DD"
                  localization="es"
                  name="selectedDate"
                  placeholder="Date Time"
                  value={selectedDate}
                  iconPosition="left"
                  onChange={(e, { value }) => {
                    setSelectedDate(value)
                  }}
                  popupPosition='top right'
                />
              </Grid.Column>
              <Grid.Column floated='right'>
                {/* <Input value={searchText} onChange={search} icon='search' placeholder='Busca por el nombre o cédula' iconPosition='left' transparent fluid /> */}
              </Grid.Column>

            </Grid.Row>
            <Grid.Row className="table-holpers">
              <Grid.Column>
                <Table celled selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Nombre</Table.HeaderCell>
                      <Table.HeaderCell>Contrataciones</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {holpers.filter(professional => (
                      professional.basicServiceStatus === 'approved' &&
                      professional.documentsStatus === 'approved' &&
                      professional.bankAccountStatus === 'approved' &&
                      professional.socialSecurityStatus === 'approved' &&
                      professional.enabled && professional.step === 'DrawerRouter')).map(p =>
                        <Table.Row onClick={() => setSelectedHolper(p)} active={selectedHolper.id === p.id}>
                          <Table.Cell>{p.name}</Table.Cell>
                          <Table.Cell>{p.hiringCount}</Table.Cell>
                        </Table.Row>
                      )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setOpenHolperUpdateModal(false)}>
            Cerrar
          </Button>
          <Button disabled={isEmpty(selectedHolper) || isEmpty(selectedDate)} positive onClick={() => enableHiring()}>
            Actualizar
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default HiringDetailsDeleted;
