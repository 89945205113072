import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Segment, Button, Grid, Message, Modal as SModal, Header, Form, Icon, Label } from 'semantic-ui-react';
import { showImage } from '../../common/utils';
import { UserActions, CommonActions } from '../../modules/actions';
import ImageViewer from '../ImageViewer';

const HolperDocument = ({ documents, status, onResult, readOnly, hasOptional, userId, container }) => {
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [docObj, setDocObj] = useState({});
  const [showButtons, setShowButtons] = useState(true);
  const [showUploadModal, setShowUploadModal] = useState(false);

  useEffect(() => {
    setShowButtons(documents.every(doc => doc));
  }, [documents]);

  const fileChange = (e) => {
    setFile(e.target.files[0]);
  }

  const updateFile = () => {
    const fileName = `${new Date().getTime()}.jpg`;

    const user = {
      id: userId,
      [docObj.key]: fileName
    };

    dispatch(CommonActions.UPLOAD_FILE({ data: file, container, name: fileName }));
    dispatch(UserActions.UPDATE_USER(user));
  }

  return (
    <>
      <Segment basic>
        <Grid columns={documents.length} textAlign='center' divided>

          <Grid.Row verticalAlign='middle'>
            {documents.map((doc, index) => (
              doc ? <Grid.Column key={`document-${index}`}>
                <ImageViewer src={showImage(doc.value, 'holper-docs')} />
                {userId && container &&
                  <Button
                    onClick={() => { setShowUploadModal(true); setDocObj(doc); setFile(null) }}
                    style={{ marginTop: 30 }}
                  >
                    Subir Archivo
                  </Button>
                }
              </Grid.Column> : null
            ))}
          </Grid.Row>

          {
            !showButtons && <Message>
              <Message.Header>Documentos no disponibles</Message.Header>
              <p>
                El Holper no ha cargado los documentos. Contacte al holper para mas información.
              </p>
            </Message>
          }


          {(hasOptional || (showButtons && !readOnly)) && (
            <Grid.Row>
              <Grid.Column>
                <Button.Group>
                  {!status && (
                    <>
                      <Button color='green' onClick={() => onResult('approved')}>Aprobar</Button>
                      <Button.Or text='o' />
                      <Button color='red' onClick={() => onResult('rejected')}>Rechazar</Button>
                    </>
                  )}
                  {status === 'approved' && (
                    <>
                      <Button color='red' onClick={() => onResult('rejected')}>Rechazar</Button>
                    </>
                  )}
                  {status === 'rejected' && (
                    <>

                      <Button color='green' onClick={() => onResult('approved')}>Aprobar</Button>
                    </>
                  )}
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width="12">
              {
                status === 'rejected' &&
                <Message>
                  <Message.Header>Documentos Rechazados</Message.Header>
                  <p>
                    Estos documentos ya fueron rechazados.
                  </p>
                </Message>
              }

              {
                status === 'approved' &&
                <Message>
                  <Message.Header>Documentos Aprobados</Message.Header>
                  <p>
                    Estos documentos ya fueron aprobados.
                  </p>
                </Message>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <SModal
        onClose={() => setShowUploadModal(false)}
        onOpen={() => setShowUploadModal(true)}
        open={showUploadModal}
        size='small'
      >
        <Header icon>
          Subir Archivo
        </Header>
        <SModal.Content>
          <Form>
            <Form.Field>
              <label>Carga el archivo </label>
              <Button as="label" htmlFor="file" type="button" animated="fade">
                <Button.Content visible>
                  <Icon name="file" />
                </Button.Content>
                <Button.Content hidden>Cargar</Button.Content>
              </Button>
              <input
                type="file"
                id="file"
                hidden
                onChange={fileChange}
              />

              <Form.Input
                fluid
                label="Archivo Cargado "
                placeholder=""
                readOnly
                value={file ? file.name : 'Nombre del Archivo'}
              />
              {
                !file &&
                <Label basic color='red' pointing>
                  El archivo es requerido.
                </Label>
              }
            </Form.Field>
          </Form>
        </SModal.Content>
        <SModal.Actions>
          <Button color='red' inverted onClick={() => setShowUploadModal(false)}>
            Cancelar
          </Button>
          <Button color='green' inverted onClick={updateFile} disabled={!file}>
            Subir Archivo
          </Button>
        </SModal.Actions>
      </SModal>
    </>
  );
};

export default HolperDocument;
